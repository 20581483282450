import warningAlertStyle from '_assets/global/jss/components/SweetAlerts/warningAlertStyle';
import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import {withStyles} from '@material-ui/core/styles';

const WarningAlert = ({
  title,
  confirmCallback,
  closeCallback,
  confirmBtnText,
  classes,
  children,
  showCancel,
  key,
  cancelBtnText,
}) => {
  return (
    <SweetAlert
      key={key}
      warning
      className={classes.sweetAlertPosition}
      title={title}
      onConfirm={confirmCallback}
      onCancel={closeCallback}
      confirmBtnCssClass={classes.button + ' ' + classes.primary}
      cancelBtnCssClass={classes.button + ' ' + classes.danger}
      confirmBtnText={confirmBtnText}
      cancelBtnText={cancelBtnText}
      showCancel={showCancel}
    >
      {children}
    </SweetAlert>
  );
};

export default withStyles(warningAlertStyle)(WarningAlert);
