import {cardTitle, grayColor20} from '_assets/global/jss/styleHelpers';
import {createMuiTheme} from '@material-ui/core';

export const deliveryOptionsPageStyle = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400',
    },
  },
  tabBar: {
    marginTop: '30px',
  },
  cell: {
    padding: '0px 5px',
    whiteSpace: 'nowrap',
    '&:first-of-type': {
      padding: '0px 5px 0px 10px',
    },
    '&:last-of-type': {
      padding: '0px 10px 0px 5px',
    },
  },
  actions: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'flex-end',
  },
  filterCard: {
    background: grayColor20,
    margin: '20px 0 50px 0',
  },
  filter: {
    padding: '0px !important',
  },
});

export const tableSelectTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiIconButton: {
      root: {
        padding: '0px',
      },
    },
  },
});
