import {
  blueButtonShadow,
  blueColor80,
  grayButton,
  grayColor20,
} from '_assets/global/jss/styleHelpers';

export const configurationFormStyle = (theme) => ({
  formActions: {
    padding: theme.spacing.unit * 3,
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: grayButton,
  blue: blueButtonShadow,
  configuration: {
    backgroundColor: grayColor20,
    padding: '20px',
    margin: '.3125rem 1px',
    boxShadow: '0px 4px 4px 0px rgb(0 0 0 / 30%)',
  },
  fieldPadding: {
    padding: '0 15px',
  },
  title: {
    padding: '5px 15px',
    fontSize: '16px',
    borderBottom: '2px solid ' + blueColor80,
    margin: '25px 0 15px 0',
    textTransform: 'uppercase',
    fontWeight: 600,
    color: blueColor80,
  },

  rootSwitchClass: {
    marginLeft: '-12px',
  },
});
