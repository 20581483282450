import {
  blackColor,
  blueButtonShadow,
  grayButton,
  grayColor50,
  hexToRgb,
  primaryColor,
} from '_assets/global/jss/styleHelpers';
import {createMuiTheme} from '@material-ui/core';

export const deliveryOptionsFormStyle = (theme) => ({
  table: {
    minWidth: 1020,
    marginTop: '20px',
  },
  tableWrapper: {
    overflow: 'overlay',
    paddingBottom: '15px',
  },
  marginBottom: {
    marginBottom: '60px',
  },
  formActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: grayButton,
  primary: {
    backgroundColor: primaryColor,
    boxShadow:
      '0 2px 2px 0 rgba(' +
      hexToRgb(primaryColor) +
      ', 0.14), 0 3px 1px -2px rgba(' +
      hexToRgb(primaryColor) +
      ', 0.2), 0 1px 5px 0 rgba(' +
      hexToRgb(primaryColor) +
      ', 0.12)',
    '&:hover,&:focus': {
      backgroundColor: primaryColor,
      boxShadow:
        '0 14px 26px -12px rgba(' +
        hexToRgb(primaryColor) +
        ', 0.42), 0 4px 23px 0px rgba(' +
        hexToRgb(blackColor) +
        ', 0.12), 0 8px 10px -5px rgba(' +
        hexToRgb(primaryColor) +
        ', 0.2)',
    },
  },
  blue: blueButtonShadow,
  margin: {
    marginTop: '20px',
  },
  flex: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '30px',
  },
});

export const tableHeadTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiTableHead: {
      root: {
        backgroundColor: `${grayColor50} !important`,
      },
    },
  },
});

export const tableHeadLightTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiTableHead: {
      root: {
        backgroundColor: `${grayColor50} !important`,
      },
    },
  },
});
