import {
  blackColor,
  blueButtonShadow,
  grayButton,
  grayColor60,
  hexToRgb,
  primaryColor,
} from '_assets/global/jss/styleHelpers';
import {createMuiTheme} from '@material-ui/core';

export const customerFormStyle = (theme) => ({
  fixFields: {
    height: '28px',
    borderBottom: `${grayColor60} 1px solid`,
    fontSize: '16px',
    margin: '30px 0 0 0',
    '& span': {
      fontSize: '14px',
      marginRight: '10px',
    },
  },
  button: grayButton,
  primary: {
    backgroundColor: primaryColor,
    boxShadow:
      '0 2px 2px 0 rgba(' +
      hexToRgb(primaryColor) +
      ', 0.14), 0 3px 1px -2px rgba(' +
      hexToRgb(primaryColor) +
      ', 0.2), 0 1px 5px 0 rgba(' +
      hexToRgb(primaryColor) +
      ', 0.12)',
    '&:hover,&:focus': {
      backgroundColor: primaryColor,
      boxShadow:
        '0 14px 26px -12px rgba(' +
        hexToRgb(primaryColor) +
        ', 0.42), 0 4px 23px 0px rgba(' +
        hexToRgb(blackColor) +
        ', 0.12), 0 8px 10px -5px rgba(' +
        hexToRgb(primaryColor) +
        ', 0.2)',
    },
  },
  blue: blueButtonShadow,
  formActions: {
    borderTop: '3px solid #fff',
    marginTop: '45px',
    padding: theme.spacing.unit * 3,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  margin: {
    margin: '20px 20px',
    paddingTop: '20px',
  },
  flex: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '30px',
  },
  separation: {
    marginTop: '30px',
    borderTop: '3px solid #fff',
  },
});

export const selectFieldTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiFormControl: {
      root: {
        minWidth: '90% !important',
      },
    },
  },
});
