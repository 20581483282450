import {userFormStyle} from '_assets/projectSpecific/jss/components/Forms/userFormStyle';
import Button from '_components/global/Buttons/Button';
import {
  FormCheckbox,
  FormSelectField,
  FormSwitcher,
  FormTextField,
} from '_components/global/Form/FormFields';
import GridContainer from '_components/global/Grid/GridContainer';
import GridItem from '_components/global/Grid/GridItem';
import {LANGUAGES} from '_config/configConstants';
import {Can} from '_helpers/global/AuthorizedAbilityContext';
import {
  email,
  maxLength256,
  required,
  validatePasswordPolicies,
} from '_helpers/global/FieldLevelValidation';
import cx from 'classnames';
import React from 'react';
import compose from 'recompose/compose';
import {Field, reduxForm} from 'redux-form';
import {Trans} from '@lingui/macro';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import {withStyles} from '@material-ui/core/styles';
import {Person, Visibility, VisibilityOff} from '@material-ui/icons';

const UserForm = ({
  handleSubmit,
  pristine,
  reset,
  submitting,
  submitName,
  form,
  branchesOptions,
  roleOptions,
  userId,
  customerNo,
  classes,
  authType,
}) => {
  const [values, setValues] = React.useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({...values, showPassword: !values.showPassword});
  };

  return (
    <form onSubmit={handleSubmit} className={classes.userForm}>
      <GridContainer>
        {form === 'editUser' && (
          <>
            <GridItem xs={12} md={6}>
              <div className={classes.fixFields}>
                <span>
                  <Trans>ID</Trans> :
                </span>
                {userId ? userId : null}
              </div>
            </GridItem>
            <GridItem xs={12} md={6}>
              <div className={classes.fixFields}>
                <span>
                  <Trans>CUSTOMER ID</Trans> :
                </span>
                {customerNo ? customerNo : null}
              </div>
            </GridItem>
          </>
        )}
        <GridItem xs={12} md={6}>
          <Field
            name="name"
            label={<Trans>Name</Trans>}
            id="name"
            component={FormTextField}
            validate={[required, maxLength256]}
            required
          />
        </GridItem>
        <GridItem xs={12} md={6}>
          <Field
            name="oracle_id"
            htmlFor="oracle_id"
            label={<Trans>Webuser No.</Trans>}
            id="oracle_id"
            component={FormTextField}
            validate={maxLength256}
          />
        </GridItem>
        <GridItem xs={12} md={6}>
          <Field
            name="email"
            label={<Trans>Email</Trans>}
            id="email"
            type="email"
            validate={[email, maxLength256]}
            component={FormTextField}
          />
        </GridItem>
        {form === 'createUser' && (
          <GridItem xs={12} md={6}>
            <Field
              name="customer_no"
              htmlFor="customer_no"
              label={<Trans>Customer ID</Trans>}
              id="customer_no"
              component={FormTextField}
              validate={maxLength256}
            />
          </GridItem>
        )}
        <GridItem xs={12} md={6}>
          <Field
            name="language"
            htmlFor="language"
            label={<Trans>Default language</Trans>}
            id="language"
            type="select"
            component={FormSelectField}
            options={LANGUAGES}
          />
        </GridItem>
        <GridItem xs={12} md={6}>
          <Field
            name="branch"
            htmlFor="branch"
            label={<Trans>Branch</Trans>}
            id="branch"
            type="select"
            component={FormSelectField}
            options={branchesOptions}
          />
        </GridItem>
        <GridItem xs={12} md={6}>
          <Field
            name="groups"
            htmlFor="role"
            label={<Trans>Role</Trans>}
            id="groups"
            type="select"
            component={FormSelectField}
            options={roleOptions}
          />
        </GridItem>
        <GridItem xs={12} md={6}>
          <Field
            name="enable_automatic_order"
            component={FormCheckbox}
            label={<Trans>Send basket automatically</Trans>}
          />
        </GridItem>
        <GridItem xs={12} md={6}>
          <Field
            name="haynespro_user"
            label={<Trans>Haynesspro username</Trans>}
            id="haynespro_user"
            validate={[maxLength256]}
            component={FormTextField}
          />
        </GridItem>
        <GridItem xs={12}>
          <hr className={classes.separation} />
        </GridItem>
        <GridItem xs={12} md={6}>
          <Field
            name="username"
            label={<Trans>Login name</Trans>}
            id="username"
            additionalInputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton disabled aria-label="username">
                    <Person />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            component={FormTextField}
            validate={form === 'createUser' ? required : []}
            required={form === 'createUser'}
          />
        </GridItem>
        {!authType ? (
          <GridItem xs={12} md={6}>
            <Field
              name="password"
              label={<Trans>Password</Trans>}
              type={values.showPassword ? 'text' : 'password'}
              additionalInputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              component={FormTextField}
              validate={form === 'createUser' ? validatePasswordPolicies : []}
              required={form === 'createUser'}
            />
          </GridItem>
        ) : (
          <></>
        )}
        <Can I="changeAuthType" a="Users">
          <GridItem xs={12} md={6}>
            <Field
              name="auth_type"
              component={FormSwitcher}
              helpText={<Trans>Help text for setting auth type</Trans>}
              valueA="Local type"
              valueB="Oracle type"
            />
          </GridItem>
        </Can>
        {!authType ? (
          <GridItem xs={12} md={6}>
            <Field
              name="repeatPassword"
              label={<Trans>Repeat password</Trans>}
              type={values.showPassword ? 'text' : 'password'}
              additionalInputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              component={FormTextField}
              validate={form === 'createUser' ? validatePasswordPolicies : []}
              required={form === 'createUser'}
            />
          </GridItem>
        ) : (
          <></>
        )}
        <GridItem xs={12}>
          <div className={classes.formActions}>
            <Button
              onClick={reset}
              className={cx(classes.button)}
              disabled={pristine || submitting}
            >
              <Trans>Reset</Trans>
            </Button>
            <Button color={'primary'} disabled={pristine || submitting} type="submit">
              {submitName}
            </Button>
          </div>
        </GridItem>
      </GridContainer>
    </form>
  );
};

export default compose(
  withStyles(userFormStyle),
  reduxForm({
    enableReinitialize: true,
  })
)(UserForm);
