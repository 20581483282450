import {blueButtonShadow, grayButton} from '_assets/global/jss/styleHelpers';

export const deleteAllGeneralFilesStyle = (theme) => ({
  formActions: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: grayButton,
  blue: blueButtonShadow,
});
