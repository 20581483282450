import {blueButtonShadow, grayButton, grayColor60} from '_assets/global/jss/styleHelpers';

export const userFormStyle = (theme) => ({
  fixFields: {
    height: '28px',
    borderBottom: `${grayColor60} 1px solid`,
    fontSize: '16px',
    margin: '30px 0 0 0',
    '& span': {
      fontSize: '14px',
      marginRight: '10px',
    },
  },
  userForm: {
    position: 'relative',
    padding: '20px',
  },
  formActions: {
    borderTop: '3px solid #fff',
    marginTop: '45px',
    padding: theme.spacing.unit * 3,
    paddingTop: '40px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: grayButton,
  blue: blueButtonShadow,
  separation: {
    marginTop: '30px',
    borderTop: '3px solid #fff',
  },
});
