import {grayColor50, grayColor60} from '_assets/global/jss/styleHelpers';
import {createMuiTheme} from '@material-ui/core';

export const detailCartFormStyle = (theme) => ({
  firstRow: {
    height: '35px',
    paddingTop: '15px',
  },
  lastRow: {
    height: '45px',
    paddingTop: '15px',
    marginTop: '15px',
  },
  rowWithSelect: {
    height: '70px',
    paddingTop: '20px',
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflow: 'overlay',
    paddingBottom: '15px',
  },
  saveBtn: {
    margin: '30px 0 20px 0',
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
  },
  hidden: {
    display: 'none',
  },
  deliverField: {
    marginTop: '-15px',
  },
  orderField: {
    marginTop: '-10px',
  },
  smallIcon: {
    '& svg': {
      width: '14px',
      height: '14px',
      fill: grayColor60,
      marginLeft: '2px',
    },
    display: 'flex',
  },
  flexSpace: {
    display: 'flex',
    justifyContent: 'space-between',
    '& a': {
      fontWeight: 'bold',
    },
  },
  cell: {
    padding: '0px 5px',
    whiteSpace: 'nowrap',
    '&:first-of-type': {
      padding: '0px 5px 0px 10px',
    },
    '&:last-of-type': {
      padding: '0px 10px 0px 5px',
    },
  },
  activeNumber: {
    display: 'flex',
    flexDirection: 'column',
  },
  description: {
    maxWidth: '220px',
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    '& button': {
      marginLeft: '10px',
    },
  },
  popover: {
    pointerEvents: 'none',
    '& p': {
      padding: '5px 10px',
    },
  },
});

export const selectFieldTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiFormControl: {
      root: {
        margin: '-10px auto 16px auto !important',
      },
    },
  },
});

export const tableHeadTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiTableHead: {
      root: {
        backgroundColor: `${grayColor50} !important`,
      },
    },
  },
});
