import {login, logout} from '_actions/global/authActions';
import {updateLoader} from '_actions/global/loaderActions';
import {editMyUserSettings} from '_actions/projectSpecific/settingsActions';
import {impersonateStop} from '_actions/projectSpecific/usersActions';
import adminNavbarLinksStyle from '_assets/global/jss/components/Navbar/adminNavbarLinksStyle';
import Button from '_components/global/Buttons/Button';
import GlobalSettingsDialog from '_components/projectSpecific/Dialogs/GlobalSettingsDialog';
import {Can} from '_helpers/global/AuthorizedAbilityContext';
import {actualUserSelector, fireErrorToast, fireSuccessToast} from '_helpers/global/functions';
import cx from 'classnames';
import {diff} from 'deep-object-diff';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {bindActionCreators} from 'redux';
import {Trans} from '@lingui/macro';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import withStyles from '@material-ui/core/styles/withStyles';
import {Gamepad, PanTool, Person, PowerSettingsNew, SettingsApplications} from '@material-ui/icons';

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      login,
      logout,
      updateLoader,
      impersonateStop,
      editMyUserSettings,
    },
    dispatch
  );
};

class AdminNavbarLinks extends React.Component {
  toastId = null;

  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      userDrop: null,
      openSettingsModal: false,
    };
  }

  handleUserClick = (event) => {
    this.setState({userDrop: event.currentTarget});
  };

  handleUserClose = () => {
    this.setState({userDrop: null});
  };

  handleLogout = () => {
    return this.props.logout();
  };

  getUserIdentity = () => {
    if (this.props.loggedUser) {
      if (Object.keys(this.props.impersonatedUser).length !== 0) {
        return (
          <>
            {this.props.loggedUser.username} (<Gamepad />
            {this.props.impersonatedUser.username})
          </>
        );
      }
      return this.props.loggedUser.username;
    }
    return <Trans>user</Trans>;
  };

  handleOpenSettingsDialog = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      openSettingsModal: true,
    });
  };

  handleCloseSettingsDialog = () => {
    this.setState({
      openSettingsModal: false,
    });
  };

  getInitValues = (user) => {
    const initValues = {...user};

    if (user.auth_type === 'oracle') {
      initValues.auth_type = true;
    } else {
      initValues.auth_type = false;
    }
    return initValues;
  };

  editCurrentUser = (values, user) => {
    values.password = values.newPassword;
    delete values.repeat_password;
    delete values.newPassword;
    delete values.username;
    delete values.name;
    const editedUser = diff(user, values);

    if (editedUser.auth_type === true) {
      editedUser.auth_type = 'oracle';
    } else {
      editedUser.auth_type = 'local';
    }

    this.props.updateLoader({globalLoading: true});
    return this.props
      .editMyUserSettings(editedUser)
      .then(() => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireSuccessToast(
          <Trans>Changes in settings were successfully saved.</Trans>
        );
        this.handleCloseSettingsDialog();
      })
      .catch((error) => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireErrorToast(error.errors._error, {autoClose: false});
        this.handleCloseSettingsDialog();
      });
  };

  impersonateStop = (e) => {
    e.preventDefault();
    this.setState(
      {
        userDrop: null,
      },
      () => this.props.impersonateStop()
    );
  };

  render() {
    const {classes, currentUser} = this.props;
    const wrapper = cx({});
    const managerClasses = cx({
      [classes.managerClasses]: true,
    });
    const {userDrop} = this.state;

    return (
      <div className={wrapper}>
        <div className={managerClasses}>
          <div className={classes.navbar}>
            {currentUser && (
              <>
                <GlobalSettingsDialog
                  open={this.state.openSettingsModal}
                  handleClose={this.handleCloseSettingsDialog}
                  initValues={this.getInitValues(currentUser)}
                  onSubmit={(values) => {
                    return this.editCurrentUser(values, currentUser);
                  }}
                />
                <div>
                  <Button
                    color="transparent"
                    aria-label="User"
                    aria-owns={userDrop ? 'simple-menu-2' : null}
                    onClick={this.handleUserClick}
                    className={cx(classes.buttonLink, classes.userDisplay)}
                    buttonRef={(node) => {
                      this.anchorEl = node;
                    }}
                  >
                    <Person className={classes.headerLinksSvg + ' ' + classes.links} />
                    <span>{this.getUserIdentity()}</span>
                  </Button>

                  <Menu
                    id="simple-menu-2"
                    anchorEl={userDrop}
                    open={Boolean(userDrop)}
                    onClose={this.handleUserClose}
                  >
                    {Object.keys(this.props.impersonatedUser).length !== 0 && (
                      <MenuItem onClick={(e) => this.impersonateStop(e)}>
                        <PanTool
                          className={cx(classes.headerLinksSvg, classes.links, classes.marginRight)}
                        />
                        Stop impersonating
                      </MenuItem>
                    )}
                    <Can I="read" a="MyUser">
                      <MenuItem onClick={(e) => this.handleOpenSettingsDialog(e)}>
                        <SettingsApplications
                          className={cx(classes.headerLinksSvg, classes.links, classes.marginRight)}
                        />
                        Settings
                      </MenuItem>
                    </Can>
                    <MenuItem onClick={() => this.handleLogout()}>
                      <PowerSettingsNew
                        className={cx(classes.headerLinksSvg, classes.links, classes.marginRight)}
                      />
                      Log out
                    </MenuItem>
                  </Menu>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

AdminNavbarLinks.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(adminNavbarLinksStyle),
  connect((store) => {
    return {
      loggedUser: store.Auth.logged,
      impersonatedUser: store.Auth.impersonated,
      currentUser: actualUserSelector(store.Auth),
    };
  }, mapDispatchToProps)
)(AdminNavbarLinks);
