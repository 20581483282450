import {updateGlobalSettings} from '_actions/global/globalSettingsActions';
import {updateLoader} from '_actions/global/loaderActions';
import {
  getDiscountLimitCodes,
  saveDiscountLimitCodesChanges,
} from '_actions/projectSpecific/discountLimitCodesActions';
import {discountGroupsTabStyle} from '_assets/projectSpecific/jss/components/Tabs/discountGroupsTabStyle';
import GridContainer from '_components/global/Grid/GridContainer';
import GridItem from '_components/global/Grid/GridItem';
import DiscountLimitCodesListForm from '_components/projectSpecific/Forms/DiscountLimitCodesListForm';
import DiscountLimitCodesTableToolbar from '_components/projectSpecific/Toolbars/DiscountLimitCodesTableToolbar';
import {Can} from '_helpers/global/AuthorizedAbilityContext';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {bindActionCreators} from 'redux';
import {getFormValues} from 'redux-form';
import {Trans} from '@lingui/macro';
import {withStyles} from '@material-ui/core/styles';

const defaultRows = [
  {
    id: 'limit_code',
    label: <Trans>Category Code</Trans>,
    sortable: false,
  },
];

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateLoader,
      updateGlobalSettings,
      getDiscountLimitCodes,
      saveDiscountLimitCodesChanges,
    },
    dispatch
  );
};

class DiscountLimitCodesTab extends Component {
  toastId = null;

  constructor(props) {
    super(props);
    this.state = {
      inputLocked: true,
      rows: [...defaultRows],
    };
  }

  getDynamicRows = () => {
    if (this.props.discountLimitCodes.data[0]) {
      return this.props.discountLimitCodes.data[0].discount_limits.map((limit) => {
        return {id: `${limit.id}`, label: `${limit.discount_group_name}`, sortable: false};
      });
    }
    return [];
  };

  componentDidMount = () => {
    this.fetchDiscountLimitCodes();
  };

  fetchDiscountLimitCodes = () => {
    return this.props.getDiscountLimitCodes().then(() => {
      this.setState({rows: [...defaultRows, ...this.getDynamicRows()]});
    });
  };

  handleEnableEdit = (event) => {
    this.fetchDiscountLimitCodes().then(() => {
      this.setState({inputLocked: false});
    });
  };

  handleSaveChanges = (event) => {
    this.setState({inputLocked: true});
    this.props.saveDiscountLimitCodesChanges(this.props.discountLimitCodesListFormData);
  };

  render() {
    const {classes} = this.props;
    return (
      <GridContainer>
        {this.state.alert}

        <GridItem xs={12}>
          <Can I="update" a="DiscountGroup">
            <DiscountLimitCodesTableToolbar
              handleEdit={this.handleEnableEdit}
              handleSave={this.handleSaveChanges}
              inputLocked={this.state.inputLocked}
            />
          </Can>
          <div className={classes.tableWrapper}>
            <DiscountLimitCodesListForm
              form="discountLimitCodesListForm"
              initialValues={this.props.initialValues}
              discountLimitCodes={this.props.discountLimitCodes}
              rows={this.state.rows}
              inputLocked={this.state.inputLocked}
            />
          </div>
        </GridItem>
      </GridContainer>
    );
  }
}

export default compose(
  withStyles(discountGroupsTabStyle),
  connect((store) => {
    return {
      discountLimitCodesListFilter: store.AppFilterStates.discountLimitCodesListFilter,
      globalSettings: store.GlobalSettings,
      discountLimitCodesListFormData: getFormValues('discountLimitCodesListForm')(store),
      initialValues: store.DiscountLimitCodes.initialFormValues,
      discountLimitCodes: store.DiscountLimitCodes,
    };
  }, mapDispatchToProps)
)(DiscountLimitCodesTab);
