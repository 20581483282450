import {AppFilterStatesReducer} from '_reducers/global/AppFilterStatesReducer';
import {AuthReducer} from '_reducers/global/AuthReducer';
import {LoaderReducer, LocalLoaderReducer} from '_reducers/global/LoaderReducer';
import {NotificationReducer} from '_reducers/global/NotificationReducer';
import {globalSettingsReducer} from '_reducers/global/globalSettingsReducer';
import {BranchesReducer} from '_reducers/projectSpecific/BranchReducer';
import {
  CartLockInfoReducer,
  CartReducer,
  CartsReducer,
} from '_reducers/projectSpecific/CartReducer';
import {ConfigReducer} from '_reducers/projectSpecific/ConfigReducer';
import {
  CustomerReducer,
  CustomersDeliveryOptionsReducer,
  CustomersInDiscountGroupsInsertReducer,
  CustomersInDiscountGroupsReducer,
  CustomersReducer,
} from '_reducers/projectSpecific/CustomerReducer';
import {CustomerSettingsReducer} from '_reducers/projectSpecific/CustomerSettingsReducer';
import {
  DeliveryOptionsReducer,
  MyDeliveryOptionsReducer,
} from '_reducers/projectSpecific/DeliveryOptionsReducer';
import {DiscountCategoriesReducer} from '_reducers/projectSpecific/DiscountCategoryReducer';
import {DiscountGroupsReducer} from '_reducers/projectSpecific/DiscountGroupReducer';
import {DiscountLimitCodesReducer} from '_reducers/projectSpecific/DiscountLimitCodesReducer';
import {GroupsReducer} from '_reducers/projectSpecific/GroupReducer';
import {LastLoginReducer} from '_reducers/projectSpecific/LastLoginReducer';
import {LostSaleReasonsReducer} from '_reducers/projectSpecific/LostSaleReasonReducer';
import {PermissionsReducer} from '_reducers/projectSpecific/PermissionReducer';
import {
  UserPermissionsReducer,
  UserReducer,
  UsersReducer,
} from '_reducers/projectSpecific/UserReducer';
import {VehicleReferencesReducer} from '_reducers/projectSpecific/VehicleReferencesReducer';
import {WholesalerAvailabilityReducer} from '_reducers/projectSpecific/WholesalerAvailabilityReducer';
import {combineReducers} from 'redux';
import {reducer as formReducer} from 'redux-form';

const rootReducer = combineReducers({
  // for redux-form usage
  form: formReducer,
  AppFilterStates: AppFilterStatesReducer,
  Loader: LoaderReducer,
  LocalLoader: LocalLoaderReducer,
  GlobalSettings: globalSettingsReducer,
  Notifications: NotificationReducer,
  // project specific
  Users: UsersReducer,
  User: UserReducer,
  UserPermissions: UserPermissionsReducer,
  Carts: CartsReducer,
  Cart: CartReducer,
  LockInfo: CartLockInfoReducer,
  Customers: CustomersReducer,
  Customer: CustomerReducer,
  Branches: BranchesReducer,
  DeliveryOptions: DeliveryOptionsReducer,
  Permissions: PermissionsReducer,
  Groups: GroupsReducer,
  LostSaleReasons: LostSaleReasonsReducer,
  LastLogin: LastLoginReducer,
  CustomersDeliveryOptions: CustomersDeliveryOptionsReducer,
  MyCustomerSettings: CustomerSettingsReducer,
  Auth: AuthReducer,
  DiscountCategories: DiscountCategoriesReducer,
  DiscountGroups: DiscountGroupsReducer,
  CustomersInDiscountGroups: CustomersInDiscountGroupsReducer,
  CustomersInDiscountGroupsInsert: CustomersInDiscountGroupsInsertReducer,
  DiscountLimitCodes: DiscountLimitCodesReducer,
  MyDeliveryOptions: MyDeliveryOptionsReducer,
  Config: ConfigReducer,
  WholesalerAvailability: WholesalerAvailabilityReducer,
  VehicleReferences: VehicleReferencesReducer,
});

export default rootReducer;
