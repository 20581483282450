import {blueColor20, blueColor80, cardTitle, successColor} from '_assets/global/jss/styleHelpers';
import {createMuiTheme} from '@material-ui/core/styles/index';

export const accountsPageStyle = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflow: 'overlay',
    paddingBottom: '15px',
  },
  actions: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'flex-end',
  },
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400',
    },
  },
  margin: {
    margin: theme.spacing.unit,
  },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  },
  customLink: {
    color: blueColor80,
    textDecoration: 'none',
    '&:hover': {
      color: blueColor20,
    },
  },
  userPermissions: {
    maxWidth: '150px',
    minWidth: '300px',
  },
  cell: {
    padding: '0px 5px',
    whiteSpace: 'nowrap',
    '&:first-of-type': {
      padding: '0px 5px 0px 10px',
    },
    '&:last-of-type': {
      padding: '0px 10px 0px 5px',
    },
  },
  notificationIcon: {
    fontSize: '1rem',
  },
});

export const iconTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    secondary: {
      main: successColor[0],
    },
  },
});
