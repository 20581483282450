import {updateLoader} from '_actions/global/loaderActions';
import {addUserPermission, getPermissions} from '_actions/projectSpecific/permissionsActions';
import {getUser} from '_actions/projectSpecific/usersActions';
import {
  iconTheme,
  tabContainerTheme,
  userProfilePageStyle,
} from '_assets/projectSpecific/jss/views/userProfilePageStyle';
import Button from '_components/global/Buttons/Button';
import Card from '_components/global/Card/Card';
import CardBody from '_components/global/Card/CardBody';
import CardHeader from '_components/global/Card/CardHeader';
import CardIcon from '_components/global/Card/CardIcon';
import GridContainer from '_components/global/Grid/GridContainer';
import GridItem from '_components/global/Grid/GridItem';
import SwipeableView from '_components/global/Tabs/SwipeableViews';
import TabContainer from '_components/global/Tabs/TabContainer';
import Tag from '_components/global/Tags/Tag';
import PermissionsForm from '_components/projectSpecific/Forms/PermissionsForm';
import CommonTabs from '_components/projectSpecific/Tabs/CommonTabs';
import {DATE_TIME_FORMAT, LANGUAGES} from '_config/configConstants';
import authorizedAbility from '_helpers/global/Authorization';
import {Can} from '_helpers/global/AuthorizedAbilityContext';
import {fireErrorToast, fireSuccessToast} from '_helpers/global/functions';
import {browserHistoryAccessor} from 'App/history';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import compose from 'recompose/compose';
import {bindActionCreators} from 'redux';
import {Trans} from '@lingui/macro';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tooltip from '@material-ui/core/Tooltip';
import {MuiThemeProvider, withStyles} from '@material-ui/core/styles';
import {ArrowBack, Block, CheckCircleOutline, Edit, Visibility} from '@material-ui/icons';

const rows2 = [
  {
    id: 'empty',
    label: '',
    sortable: false,
  },
  {
    id: 'code',
    label: <Trans>Code</Trans>,
    sortable: false,
  },
  {
    id: 'Name',
    label: <Trans>Name</Trans>,
    sortable: false,
  },
];

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getUser,
      updateLoader,
      addUserPermission,
      getPermissions,
    },
    dispatch
  );
};

class UserProfilePage extends React.Component {
  toastId = null;

  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      initPermissions: false,
      initUsers: false,
    };
    if (authorizedAbility.cannot('read', 'Users')) {
      browserHistoryAccessor.push('/admin/404');
    }
  }

  componentDidMount() {
    this.fetchUser();
  }

  fetchUser = () => {
    this.props.updateLoader({globalLoading: true});
    const {id} = this.props.match.params;
    return this.props.getUser(id).then(() => {
      this.setState({userPermissionsList: this.props.user.permissions});
      this.props.updateLoader({globalLoading: false});
    });
  };

  fetchPermission = () => {
    return this.props.getPermissions(0, 0);
  };

  handleUserDetailPermissionsTabClick = (event) => {
    event.preventDefault();
    !this.state.initPermissions &&
      this.setState(
        {
          initPermissions: true,
        },
        () => {
          this.fetchPermission();
        }
      );
  };

  handleUserDetailTabClick = (event) => {
    event.preventDefault();
    !this.state.initUsers &&
      this.setState(
        {
          initUsers: true,
        },
        () => {
          this.fetchUser();
        }
      );
  };

  getUserDetailPermissionsInit = (permissions) => {
    const initValues = {};
    initValues.options = {};

    permissions.forEach((item) => {
      initValues.options[item.id] = true;
    });

    return initValues;
  };

  handleChange = (event, tabValue) => {
    this.setState({tabValue});
  };

  handleChangeIndex = (index) => {
    this.setState({tabValue: index});
  };

  editUserDetailPermissions = (id, values) => {
    const listIds = [];
    listIds.push(id);
    const listPermissions = Object.keys(values.options)
      .filter((value) => values.options[value] === true)
      .map((value) => parseInt(value));
    this.props.updateLoader({globalLoading: true});
    return this.props
      .addUserPermission(listIds, listPermissions, true, false)
      .then(() => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireSuccessToast(<Trans>Permissions were added/edited.</Trans>);
      })
      .catch((error) => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireErrorToast(error.errors._error, {autoClose: false});
      });
  };

  prepareTabs = () => {
    const tabs = [
      <Tab
        key="detail"
        label={<Trans>User detail</Trans>}
        onClick={this.handleUserDetailTabClick}
      />,
    ];
    if (authorizedAbility.can('update', 'UserPermissionMembership')) {
      tabs.push(
        <Tab
          key="permissions"
          label={<Trans>Permissions</Trans>}
          onClick={this.handleUserDetailPermissionsTabClick}
        />
      );
    }
    return tabs;
  };

  render() {
    const {classes, theme} = this.props;
    const user = this.props.user;
    const {id} = this.props.match.params;
    return (
      <GridContainer justify="center">
        <GridItem xs={12}>
          {!this.props.isUserLoading && (
            <Card>
              <CardHeader color="blue" icon>
                <CardIcon color="blue">
                  <Visibility />
                </CardIcon>
                <div className={classes.cardIconWrapper}>
                  <h4 className={classes.cardIconTitle}>
                    <b>{user.username}</b>
                  </h4>
                  <div className={classes.actionButtons}>
                    <Tooltip title={<Trans>Back to list</Trans>}>
                      <Button component={Link} to="/admin/users">
                        <ArrowBack />
                      </Button>
                    </Tooltip>
                    <Can I="update" a="Users">
                      <Tooltip title={<Trans>Edit user</Trans>}>
                        <Button
                          color="success"
                          className={classes.editProfileButton}
                          component={Link}
                          to={`/admin/users/${id}/edit`}
                        >
                          <Edit />
                        </Button>
                      </Tooltip>
                    </Can>
                  </div>
                </div>
              </CardHeader>

              <CardBody className={classes.content}>
                <MuiThemeProvider theme={tabContainerTheme}>
                  <AppBar position="static" color="default">
                    <CommonTabs value={this.state.tabValue} onChange={this.handleChange}>
                      {this.prepareTabs()}
                    </CommonTabs>
                  </AppBar>
                </MuiThemeProvider>
                <SwipeableView index={this.state.tabValue} onChangeIndex={this.handleChangeIndex}>
                  <TabContainer dir={theme.direction}>
                    <GridContainer justify="center">
                      <GridItem xs={12}>
                        <div className={classes.details}>
                          <div className={classes.flex}>
                            <div className={classes.detailItem}>
                              <b>
                                <Trans>ID</Trans>:
                              </b>
                            </div>
                            <div>{id}</div>
                          </div>
                          <div className={classes.flex}>
                            <div className={classes.detailItem}>
                              <b>
                                <Trans>Name</Trans>:
                              </b>
                            </div>
                            <div>{user.name}</div>
                          </div>
                          <div className={classes.flex}>
                            <div className={classes.detailItem}>
                              <b>
                                <Trans>Username</Trans>:
                              </b>
                            </div>
                            <div>{user.username}</div>
                          </div>
                          <div className={classes.flex}>
                            <div className={classes.detailItem}>
                              <b>
                                <Trans>Email</Trans>:
                              </b>
                            </div>
                            <div>{user.email}</div>
                          </div>
                          <div className={classes.flex}>
                            <div className={classes.detailItem}>
                              <b>
                                <Trans>Auth type</Trans>:
                              </b>
                            </div>
                            <div>{user.auth_type}</div>
                          </div>
                          <div className={classes.flex}>
                            <div className={classes.detailItem}>
                              <b>
                                <Trans>Customer ID</Trans>:
                              </b>
                            </div>
                            <div>{user.customer ? user.customer.customer_no : ''}</div>
                          </div>
                          <div className={classes.flex}>
                            <div className={classes.detailItem}>
                              <b>
                                <Trans>Site ID</Trans>:
                              </b>
                            </div>
                            <div>{user.customer ? user.customer.site_id : ''}</div>
                          </div>
                          <div className={classes.flex}>
                            <div className={classes.detailItem}>
                              <b>Webuser No.:</b>
                            </div>
                            <div>{user.oracle_id}</div>
                          </div>
                          <div className={classes.flex}>
                            <div className={classes.detailItem}>
                              <b>
                                <Trans>Automatic order</Trans>:
                              </b>
                            </div>
                            <div>
                              {user.customer ? (
                                user.customer.enable_automatic_order ? (
                                  <MuiThemeProvider theme={iconTheme}>
                                    <CheckCircleOutline
                                      color="secondary"
                                      className={classes.activeIcon}
                                    />
                                  </MuiThemeProvider>
                                ) : (
                                  <Block color="secondary" className={classes.activeIcon} />
                                )
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                          <div className={classes.flex}>
                            <div className={classes.detailItem}>
                              <b>
                                <Trans>Branch</Trans>:
                              </b>
                            </div>
                            <div>{user.branch ? user.branch.name : ''}</div>
                          </div>
                          <div className={classes.flex}>
                            <div className={classes.detailItem}>
                              <b>
                                <Trans>Role</Trans>:
                              </b>
                            </div>
                            <div>
                              {user.groups && Array.isArray(user.groups) && user.groups.length
                                ? user.groups[0].name
                                : ''}
                            </div>
                          </div>
                          <div className={classes.flex}>
                            <div className={classes.detailItem}>
                              <b>
                                <Trans>Language</Trans>:
                              </b>
                            </div>
                            <div>
                              {LANGUAGES.filter((lang) => lang.value === user.language).map(
                                (lang) => lang.label
                              )}
                            </div>
                          </div>
                          <div className={classes.flex}>
                            <div className={classes.detailItem}>
                              <b>
                                <Trans>Enabled</Trans>:
                              </b>
                            </div>
                            <div>
                              {user.is_active ? (
                                <MuiThemeProvider theme={iconTheme}>
                                  <CheckCircleOutline
                                    color="secondary"
                                    className={classes.activeIcon}
                                  />
                                </MuiThemeProvider>
                              ) : (
                                <Block color="secondary" className={classes.activeIcon} />
                              )}
                            </div>
                          </div>
                          <div className={classes.flex}>
                            <div className={classes.detailItem}>
                              <b>
                                <Trans>Enable notifications</Trans>:
                              </b>
                            </div>
                            <div>
                              {user.enable_notification ? (
                                <MuiThemeProvider theme={iconTheme}>
                                  <CheckCircleOutline
                                    color="secondary"
                                    className={classes.activeIcon}
                                  />
                                </MuiThemeProvider>
                              ) : (
                                <Block color="secondary" className={classes.activeIcon} />
                              )}
                            </div>
                          </div>
                          <div className={classes.flex}>
                            <div className={classes.detailItem}>
                              <b>
                                <Trans>Last login</Trans>:
                              </b>
                            </div>
                            <div>
                              {user.last_login
                                ? moment(user.last_login).format(DATE_TIME_FORMAT)
                                : ''}
                            </div>
                          </div>
                          <div className={classes.flex}>
                            <div className={classes.detailItem}>
                              <b>
                                <Trans>Permissions</Trans>:
                              </b>
                            </div>
                            <div>
                              {user.permissions
                                ? user.permissions.map((perm) => (
                                    <Tag key={perm.id} content={perm.codename} />
                                  ))
                                : ''}
                            </div>
                          </div>
                        </div>
                      </GridItem>
                    </GridContainer>
                  </TabContainer>
                  <TabContainer dir={theme.direction}>
                    <Can I="update" a="UserPermissionMembership">
                      <GridContainer justify="center">
                        <GridItem xs={12}>
                          {this.props.permissions.length > 0 && (
                            <PermissionsForm
                              initialValues={this.getUserDetailPermissionsInit(user.permissions)}
                              form="editUserPermissionsProfile"
                              rows2={rows2}
                              options={this.props.permissions}
                              onSubmit={(values) => {
                                return this.editUserDetailPermissions(user.id, values);
                              }}
                            />
                          )}
                        </GridItem>
                      </GridContainer>
                    </Can>
                  </TabContainer>
                </SwipeableView>
              </CardBody>
            </Card>
          )}
        </GridItem>
      </GridContainer>
    );
  }
}

UserProfilePage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(userProfilePageStyle, {withTheme: true}),
  connect((store) => {
    return {
      user: store.User.data,
      isUserLoading: store.User.loading,
      permissions: store.Permissions.data,
    };
  }, mapDispatchToProps)
)(UserProfilePage);
