import {customersToDiscountGroupFormStyle} from '_assets/projectSpecific/jss/components/Tabs/customersToDiscountGroupFormStyle';
import Button from '_components/global/Buttons/Button';
import {FormCheckbox} from '_components/global/Form/FormFields';
import SimpleTableHead from '_components/global/Tables/SimpleTableHead';
import cx from 'classnames';
import React from 'react';
import compose from 'recompose/compose';
import {Field, reduxForm} from 'redux-form';
import {Trans} from '@lingui/macro';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {withStyles} from '@material-ui/core/styles';

const CustomersToDiscountGroupForm = ({
  handleSubmit,
  pristine,
  submitting,
  form,
  rows2,
  classes,
  customers,
  reset,
}) => {
  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      {!customers.loading ? (
        <>
          <div className={cx(classes.tableWrapper, classes.marginBottom)}>
            <Table className={classes.table} aria-labelledby="tableTitle">
              <SimpleTableHead rows={rows2} />
              <TableBody>
                {customers.data &&
                  customers.data.map((customer, index) => (
                    <TableRow hover tabIndex={-1} key={index}>
                      <TableCell classes={{root: classes.cell}}>
                        <Field
                          name={`options[${customer.id}]`}
                          id={`options[${customer.id}]`}
                          component={FormCheckbox}
                          typeTable={true}
                        />
                      </TableCell>
                      <TableCell classes={{root: classes.cell}}>{customer.customer_no}</TableCell>
                      <TableCell classes={{root: classes.cell}}>{customer.name}</TableCell>
                      <TableCell classes={{root: classes.cell}}>
                        {`${customer.address_1 ? customer.address_1 : ''} ${
                          customer.address_2 ? customer.address_2 : ''
                        } ${customer.address_3 ? customer.address_3 : ''} ${
                          customer.post_code ? customer.post_code : ''
                        } ${customer.city ? customer.city : ''}`}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
          <div className={classes.formActions}>
            <Button
              onClick={reset}
              className={cx(classes.button)}
              disabled={pristine || submitting}
            >
              <Trans>Reset</Trans>
            </Button>
            <Button color={'primary'} disabled={pristine || submitting} type="submit">
              <Trans>Save changes</Trans>
            </Button>
          </div>
        </>
      ) : (
        <div className={classes.circularProgress}>
          <CircularProgress />
        </div>
      )}
    </form>
  );
};

export default compose(
  withStyles(customersToDiscountGroupFormStyle, {withTheme: true}),
  reduxForm({
    enableReinitialize: true,
  })
)(CustomersToDiscountGroupForm);
