import dialogTitleStyle from '_assets/global/jss/components/Dialogs/dialogTitleStyle';
import React from 'react';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

const DialogTitle = ({children, classes, onClose}) => {
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <div>
        <Typography variant="h6" style={{textTransform: 'initial'}}>
          {children}
        </Typography>
      </div>
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

export default withStyles(dialogTitleStyle)(DialogTitle);
