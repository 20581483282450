import adminNavbarStyle from '_assets/global/jss/components/Navbar/adminNavbarStyle';
import Button from '_components/global/Buttons/Button';
import AdminNavbarLinks from '_components/projectSpecific/Navbars/AdminNavbarLinks';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import Toolbar from '@material-ui/core/Toolbar';
import withStyles from '@material-ui/core/styles/withStyles';
import {Menu} from '@material-ui/icons';

function AdminNavbar({...props}) {
  const {classes, color, brandText, handleDrawerToggle} = props;

  const appBarClasses = cx({
    [' ' + classes[color]]: color,
  });

  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <div className={classes.flex}>
          {/* Here we create navbar brand, based on route name */}
          <Button href="#" className={classes.title} color="transparent">
            {brandText}
          </Button>
        </div>
        <Hidden smDown implementation="css">
          <AdminNavbarLinks />
        </Hidden>
        <Hidden mdUp implementation="css">
          <Button
            className={classes.appResponsive}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <Menu />
          </Button>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

AdminNavbar.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
  brandText: PropTypes.object,
};

export default withStyles(adminNavbarStyle)(AdminNavbar);
