import {blueButtonShadow, grayButton} from '_assets/global/jss/styleHelpers';

export const customersToDiscountGroupFormStyle = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  cell: {
    padding: '0px 5px',
    whiteSpace: 'nowrap',
    '&:first-of-type': {
      padding: '0px 5px 0px 10px',
    },
    '&:last-of-type': {
      padding: '0px 10px 0px 5px',
    },
  },
  tableWrapper: {
    display: 'block',
  },
  formActions: {
    borderTop: '3px solid #fff',
    padding: theme.spacing.unit * 3,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: grayButton,
  blue: blueButtonShadow,
  circularProgress: {
    textAlign: 'center',
  },
  form: {
    width: '100%',
  },
});
