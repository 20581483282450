import {blueButtonShadow, grayButton} from '_assets/global/jss/styleHelpers';

export const globalDeliveryTermsFormStyle = (theme) => ({
  button: grayButton,
  blue: blueButtonShadow,
  formActions: {
    padding: theme.spacing.unit * 3,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  form: {
    paddingTop: '20px',
  },
});
