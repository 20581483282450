import signInFormStyle from '_assets/global/jss/components/Form/signInFormStyle';
import CustomButton from '_components/global/Buttons/CustomButton';
import Card from '_components/global/Card/Card';
import CardBody from '_components/global/Card/CardBody';
import CardFooter from '_components/global/Card/CardFooter';
import CardHeader from '_components/global/Card/CardHeader';
import {FormTextField} from '_components/global/Form/FormFields';
import {required} from '_helpers/global/FieldLevelValidation';
import React, {useEffect, useState} from 'react';
import compose from 'recompose/compose';
import {Field, reduxForm} from 'redux-form';
import {Trans} from '@lingui/macro';
import {withStyles} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import {Person, Visibility, VisibilityOff} from '@material-ui/icons';

const SignInForm = (props) => {
  const [cardAnimation, setCardAnimation] = useState('cardHidden');

  useEffect(() => {
    let timer1 = setTimeout(() => setCardAnimation(''), 700);
    return () => {
      clearTimeout(timer1);
    };
  }, []);

  const [values, setValues] = useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({...values, showPassword: !values.showPassword});
  };

  const {handleSubmit, error, classes} = props;
  return (
    <form onSubmit={handleSubmit}>
      <Card login className={classes[cardAnimation]}>
        <CardHeader className={`${classes.cardHeader} ${classes.textCenter}`} color="primary">
          <h4 className={classes.cardTitle}>
            <Trans>Log in to Disadmin</Trans>
          </h4>
        </CardHeader>
        <CardBody>
          <Field
            name="username"
            label={<Trans>Username</Trans>}
            type="username"
            additionalInputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="Username" disabled={true}>
                    <Person className={classes.inputAdornmentIcon} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            component={FormTextField}
            validate={[required]}
            required
          />
          <Field
            name="password"
            label={<Trans>Password</Trans>}
            type={values.showPassword ? 'text' : 'password'}
            additionalInputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={handleClickShowPassword}
                  >
                    {values.showPassword ? (
                      <Visibility className={classes.inputAdornmentIcon} />
                    ) : (
                      <VisibilityOff className={classes.inputAdornmentIcon} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            component={FormTextField}
            validate={required}
            required
          />
          {error && (
            <p className={classes.loginErrorMessage}>
              <strong>{error}</strong>
            </p>
          )}
        </CardBody>
        <CardFooter className={classes.justifyContentCenter}>
          <CustomButton color="primary" simple size="lg" block type="submit">
            <Trans>Sign in</Trans>
          </CustomButton>
        </CardFooter>
      </Card>
    </form>
  );
};

export default compose(
  withStyles(signInFormStyle),
  reduxForm({
    form: 'signIn',
  })
)(SignInForm);
