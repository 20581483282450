import Button from '_components/global/Buttons/Button';
import React from 'react';

const BaseActionsButton = (props) => {
  return (
    <Button justIcon round simple {...props}>
      {props.children}
    </Button>
  );
};

export default BaseActionsButton;
