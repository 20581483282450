import {updateGlobalSettings} from '_actions/global/globalSettingsActions';
import {updateLoader} from '_actions/global/loaderActions';
import {
  activateCustomer,
  deactivateCustomer,
  deleteCustomer,
  getCustomers,
  impersonateCustomer,
} from '_actions/projectSpecific/customersActions';
import {addCustomerPermissions, getPermissions} from '_actions/projectSpecific/permissionsActions';
import {customerPageStyle} from '_assets/projectSpecific/jss/views/customerPageStyle';
import BaseActionsButton from '_components/global/Buttons/BaseActionsButton';
import Button from '_components/global/Buttons/Button';
import Card from '_components/global/Card/Card';
import CardBody from '_components/global/Card/CardBody';
import CardHeader from '_components/global/Card/CardHeader';
import CardIcon from '_components/global/Card/CardIcon';
import Dialog from '_components/global/Dialogs/Dialog';
import GridContainer from '_components/global/Grid/GridContainer';
import GridItem from '_components/global/Grid/GridItem';
import WarningAlert from '_components/global/SweetAlerts/WarningSweetAlert';
import CustomTablePagination from '_components/global/Tables/CustomTablePagination';
import SimpleTableHead from '_components/global/Tables/SimpleTableHead';
import Tag from '_components/global/Tags/Tag';
import FilterCustomerForm from '_components/projectSpecific/Forms/FilterCustomerForm';
import PermissionsForm from '_components/projectSpecific/Forms/PermissionsForm';
import CustomerTableToolbar from '_components/projectSpecific/Toolbars/CustomerTableToolbar';
import authorizedAbility from '_helpers/global/Authorization';
import {Can} from '_helpers/global/AuthorizedAbilityContext';
import {
  fireErrorToast,
  fireSuccessToast,
  getComputedOrderBy,
  prepareSortingState,
} from '_helpers/global/functions';
import {browserHistoryAccessor} from 'App/history';
import cx from 'classnames';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import compose from 'recompose/compose';
import {bindActionCreators} from 'redux';
import {Trans} from '@lingui/macro';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import {withStyles} from '@material-ui/core/styles';
import {
  Block,
  CheckCircleOutline,
  Delete,
  DeleteForever,
  Edit,
  Gamepad,
  Group,
  Visibility,
  VpnKey,
} from '@material-ui/icons';

const rows2 = [
  {
    id: 'empty',
    label: '',
    sortable: false,
  },
  {
    id: 'code',
    label: <Trans>Code</Trans>,
    sortable: false,
  },
  {
    id: 'Name',
    label: <Trans>Name</Trans>,
    sortable: false,
  },
];

const rows = [
  {
    id: 'customer_no',
    label: <Trans>Customer ID</Trans>,
    sortable: true,
  },
  {
    id: 'site_id',
    label: <Trans>Site ID</Trans>,
    sortable: false,
  },
  {
    id: 'name_1',
    label: <Trans>Name</Trans>,
    sortable: true,
  },
  {
    id: 'username',
    label: <Trans>Login name</Trans>,
    sortable: true,
  },
  {
    id: 'main_user_groups',
    label: <Trans>Role</Trans>,
    sortable: false,
  },
  {
    id: 'discount_group',
    label: <Trans>Discount group</Trans>,
    sortable: false,
  },
  {
    id: 'permissions',
    label: <Trans>Permissions</Trans>,
    sortable: false,
  },
  {
    id: 'deliveryOptions',
    label: <Trans>Delivery options</Trans>,
    sortable: false,
  },
  {
    id: 'actions',
    label: <Trans>Actions</Trans>,
    sortable: false,
  },
];

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCustomers,
      updateLoader,
      deleteCustomer,
      getPermissions,
      addCustomerPermissions,
      updateGlobalSettings,
      impersonateCustomer,
      deactivateCustomer,
      activateCustomer,
    },
    dispatch
  );
};

class CustomersPage extends Component {
  toastId = null;

  constructor(props) {
    super(props);
    this.state = {
      order: 'asc',
      page: 0,
      orderBy: this.props.CustomerListFilters.orderBy,
      CustomerNameNo: this.props.CustomerListFilters.CustomerNameNo,
      openPermissionsDialog: false,
      customersPermissions: [],
      customerIds: [],
      customerName: '',
      openViewPermissions: false,
      openViewDT: false,
      customersDT: [],
    };
    if (authorizedAbility.cannot('read', 'Customers')) {
      browserHistoryAccessor.push('/admin/404');
    }
  }

  componentDidMount = () => {
    this.fetchCustomers();
    this.fetchPermission();
  };

  fetchCustomers = () => {
    this.props.updateLoader({globalLoading: true});
    return this.props
      .getCustomers(
        this.state.page * this.props.globalSettings.itemsPerPage,
        this.props.globalSettings.itemsPerPage,
        getComputedOrderBy(this.state.orderBy, this.state.order),
        this.state.CustomerNameNo
      )
      .then(() => {
        this.props.updateLoader({globalLoading: false});
      });
  };

  fetchPermission = () => {
    return this.props.getPermissions(0, 0);
  };

  handleRequestSort = (event, property) => {
    this.setState(prepareSortingState(this.state.orderBy, this.state.order, property), () =>
      this.fetchCustomers()
    );
  };

  handleChangePage = (event, page, value) => {
    this.setState({page, value}, () => this.fetchCustomers());
  };

  handleChangeRowsPerPage = (event) => {
    this.props.updateGlobalSettings({itemsPerPage: event.target.value}).then(() => {
      this.setState({page: 0}, () => this.fetchCustomers());
    });
  };

  filterCustomers = (values) => {
    this.setState(
      {
        CustomerNameNo: values.CustomerNameNo,
      },
      () => this.fetchCustomers()
    );
  };

  handleResetCustomersFiltering = () => {
    this.setState(
      {
        CustomerNameNo: null,
      },
      () => this.fetchCustomers()
    );
  };

  warningDeleteCustomer = (event, name, id) => {
    this.setState({
      alert: (
        <WarningAlert
          title={<Trans>Are you sure?</Trans>}
          confirmCallback={(e) => this.confirmDelete(e, name, id)}
          closeCallback={() => this.hideAlert()}
          confirmBtnText={<Trans>Yes, delete!</Trans>}
          showCancel={true}
        >
          <Trans>You will delete the customer {name}!</Trans>
        </WarningAlert>
      ),
    });
  };

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  confirmDelete = (event, name, id) => {
    this.props.updateLoader({globalLoading: true});
    return this.props
      .deleteCustomer(id, name)
      .then(() => {
        this.setState(
          {
            alert: null,
          },
          () => this.fetchCustomers()
        );
        this.toastId = fireSuccessToast(<Trans>Customer: {name} was successfully deleted.</Trans>);
      })
      .catch((error) => {
        this.setState({
          alert: null,
        });
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireErrorToast(error.errors._error, {autoClose: false});
      });
  };

  deactivateCustomer = (id, name) => {
    this.props.updateLoader({globalLoading: true});
    return this.props
      .deactivateCustomer(id)
      .then(() => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireSuccessToast(
          <Trans>Customer: {name ? name : ''} was deactivated.</Trans>
        );
        this.fetchCustomers();
      })
      .catch((error) => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireErrorToast(error.errors._error, {autoClose: false});
      });
  };

  activateCustomer = (id, name) => {
    this.props.updateLoader({globalLoading: true});
    return this.props
      .activateCustomer(id)
      .then(() => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireSuccessToast(<Trans>Customer: {name ? name : ''} was activated.</Trans>);
        this.fetchCustomers();
      })
      .catch((error) => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireErrorToast(error.errors._error, {autoClose: false});
      });
  };

  handleOpenPermissionsDialog = (event, permissions, customer) => {
    const listIds = [];
    listIds.push(customer.id);
    event.preventDefault();
    this.setState({
      alert: null,
      openPermissionsDialog: true,
      customersPermissions: permissions,
      customerIds: listIds,
      customerName: customer.name_1,
    });
  };

  handleClosePermissionsDialog = () => {
    this.setState({
      openPermissionsDialog: false,
      customersPermissions: [],
      customerIds: [],
      customerName: '',
    });
  };

  getCustomerPermissionsDialogInit = (permissions) => {
    const initValues = {};
    initValues.options = {};

    permissions.forEach((item) => {
      initValues.options[item.id] = true;
    });
    return initValues;
  };

  addCustomerDialogPermission = (values, id) => {
    const listPermissions = Object.keys(values.options)
      .filter((value) => values.options[value] === true)
      .map((value) => parseInt(value));
    this.props.updateLoader({globalLoading: true});
    return this.props
      .addCustomerPermissions(id, listPermissions, true, false)
      .then(() => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireSuccessToast(<Trans>Permissions were added/edited.</Trans>);
        this.setState(
          {
            openPermissionsDialog: false,
          },
          () => this.fetchCustomers()
        );
      })
      .catch((error) => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireErrorToast(error.errors._error, {autoClose: false});
        this.setState({
          openPermissionsDialog: false,
        });
      });
  };

  handleViewPermissionsDialogOpen = (event, customer) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      openViewPermissions: true,
      customersPermissions: customer.main_user_permissions,
      customerName: customer.name_1,
    });
  };

  handleViewPermissionsDialogClose = () => {
    this.setState({
      openViewPermissions: false,
      customersPermissions: [],
      customerName: '',
    });
  };

  handleViewDeliveryOptionsDialogOpen = (event, customer) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      openViewDT: true,
      customersDT: customer.delivery_options,
      customerName: customer.name_1,
    });
  };

  handleViewDeliveryOptionsDialogClose = () => {
    this.setState({
      openViewDT: false,
      customersDT: [],
      customerName: '',
    });
  };

  handleImpersonateCustomer = (id) => {
    this.props.impersonateCustomer(id).then((response) => {
      window.open(response.redirect_url, '_blank');
    });
  };

  render() {
    const {classes} = this.props;
    const {order, page, orderBy} = this.state;
    return (
      <GridContainer>
        {this.state.alert}
        <GridItem xs={12}>
          <Card>
            <CardHeader color="blue" icon>
              <CardIcon color="blue">
                <b>
                  <Trans>Filter</Trans>
                </b>
              </CardIcon>
            </CardHeader>
            <CardBody>
              <FilterCustomerForm
                form="filterCustomerForm"
                initialValues={this.props.CustomerListFilters}
                onSubmit={this.filterCustomers}
                handleReset={this.handleResetCustomersFiltering}
              />
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12}>
          <Card>
            <CardHeader color="blue" icon>
              <CardIcon color="blue">
                <Group />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                <Trans>Customers</Trans>
              </h4>
            </CardHeader>
            <CardBody>
              <CustomerTableToolbar />
              <Dialog
                open={this.state.openPermissionsDialog}
                handleClose={this.handleClosePermissionsDialog}
                title={<Trans>Permissions for customer {this.state.customerName}</Trans>}
                maxWidth={false}
                content={
                  <PermissionsForm
                    initialValues={this.getCustomerPermissionsDialogInit(
                      this.state.customersPermissions
                    )}
                    form="editCustomerPermissionsDialog"
                    rows2={rows2}
                    options={this.props.permissions}
                    onSubmit={(values) => {
                      return this.addCustomerDialogPermission(values, this.state.customerIds);
                    }}
                  />
                }
              />
              <Dialog
                open={this.state.openViewPermissions}
                handleClose={this.handleViewPermissionsDialogClose}
                title={<Trans>Permissions of {this.state.customerName}</Trans>}
                maxWidth={false}
                content={
                  this.state.customersPermissions &&
                  Array.isArray(this.state.customersPermissions) &&
                  this.state.customersPermissions.length &&
                  this.state.customersPermissions.map((perm) => (
                    <Tag key={perm.id} content={perm.codename} />
                  ))
                }
              />
              <Dialog
                open={this.state.openViewDT}
                handleClose={this.handleViewDeliveryOptionsDialogClose}
                title={<Trans>Delivery options of {this.state.customerName}</Trans>}
                maxWidth={false}
                content={
                  this.state.customersDT &&
                  Array.isArray(this.state.customersDT) &&
                  this.state.customersDT.length &&
                  this.state.customersDT.map((option) => (
                    <Tag key={option.id} content={option.name} />
                  ))
                }
              />
              <div className={classes.tableWrapper}>
                <Table className={classes.table} aria-labelledby="tableTitle">
                  <SimpleTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={this.handleRequestSort}
                    rowCount={this.props.customers.length}
                    rows={rows}
                  />
                  <TableBody>
                    {this.props.customers.map((customer) => {
                      const {
                        id,
                        customer_no,
                        site_id,
                        name,
                        main_user_username,
                        main_user_permissions,
                        main_user_groups,
                        delivery_options,
                        discount_group,
                        is_active,
                      } = customer;

                      return (
                        <TableRow hover tabIndex={-1} key={id}>
                          <TableCell classes={{root: classes.cell}}>{customer_no}</TableCell>
                          <TableCell classes={{root: classes.cell}}>{site_id}</TableCell>
                          <TableCell classes={{root: classes.cell}}>{name}</TableCell>
                          <TableCell classes={{root: classes.cell}}>{main_user_username}</TableCell>
                          <TableCell classes={{root: classes.cell}}>
                            {main_user_groups.length ? (
                              <Tag
                                customClasses={classes.groups}
                                key={id}
                                content={main_user_groups[0].name}
                              />
                            ) : (
                              ''
                            )}
                          </TableCell>
                          <TableCell classes={{root: classes.cell}}>
                            {discount_group ? <Tag key={id} content={discount_group.name} /> : ''}
                          </TableCell>
                          <TableCell classes={{root: classes.cell}}>
                            {main_user_permissions && main_user_permissions.length ? (
                              <Button
                                size="sm"
                                onClick={(e) => this.handleViewPermissionsDialogOpen(e, customer)}
                              >
                                <Trans>View permissions</Trans>
                              </Button>
                            ) : (
                              ''
                            )}
                          </TableCell>
                          <TableCell classes={{root: classes.cell}}>
                            {delivery_options && delivery_options.length ? (
                              <Button
                                size="sm"
                                onClick={(e) =>
                                  this.handleViewDeliveryOptionsDialogOpen(e, customer)
                                }
                              >
                                <Trans>View Delivery terms</Trans>
                              </Button>
                            ) : (
                              ''
                            )}
                          </TableCell>
                          <TableCell classes={{root: classes.cell}}>
                            <div className={cx('actions-right', classes.actions)}>
                              <Can I="read" a="ImpersonateCustomer">
                                <BaseActionsButton
                                  color="warning"
                                  onClick={() => this.handleImpersonateCustomer(id)}
                                >
                                  <Tooltip title={<Trans>Login as customer</Trans>}>
                                    <Gamepad />
                                  </Tooltip>
                                </BaseActionsButton>
                              </Can>
                              <BaseActionsButton
                                color="info"
                                component={Link}
                                to={`/admin/customers/${id}`}
                              >
                                <Tooltip title={<Trans>Customer detail</Trans>}>
                                  <Visibility />
                                </Tooltip>
                              </BaseActionsButton>
                              <Can I="update" a="Customers">
                                <BaseActionsButton
                                  color="success"
                                  component={Link}
                                  to={`/admin/customers/${id}/edit`}
                                >
                                  <Tooltip title={<Trans>Edit customer</Trans>}>
                                    <Edit />
                                  </Tooltip>
                                </BaseActionsButton>
                              </Can>
                              <Can I="update" a="CustomerPermissionMembership">
                                <BaseActionsButton
                                  color="purple"
                                  onClick={(event) =>
                                    this.handleOpenPermissionsDialog(
                                      event,
                                      main_user_permissions,
                                      customer
                                    )
                                  }
                                >
                                  <Tooltip title={<Trans>Modify Permissions</Trans>}>
                                    <VpnKey />
                                  </Tooltip>
                                </BaseActionsButton>
                              </Can>
                              <Can I={'activate'} a={'Customers'}>
                                {is_active ? (
                                  <BaseActionsButton
                                    color="danger"
                                    onClick={() => this.deactivateCustomer(id, name)}
                                  >
                                    <Tooltip title={<Trans>Deactivate customer</Trans>}>
                                      <Block />
                                    </Tooltip>
                                  </BaseActionsButton>
                                ) : (
                                  <BaseActionsButton
                                    color="success"
                                    onClick={() => this.activateCustomer(id, name)}
                                  >
                                    <Tooltip title={<Trans>Activate customer</Trans>}>
                                      <CheckCircleOutline />
                                    </Tooltip>
                                  </BaseActionsButton>
                                )}
                              </Can>
                              <Can I="delete" a="Customers">
                                {main_user_groups.length &&
                                main_user_groups[0].name === 'reseller' ? (
                                  <BaseActionsButton className={classes.disableDelete}>
                                    <Tooltip
                                      title={<Trans>Cannot delete customer type reseller</Trans>}
                                    >
                                      <DeleteForever />
                                    </Tooltip>
                                  </BaseActionsButton>
                                ) : (
                                  <BaseActionsButton
                                    color="danger"
                                    onClick={(event) => this.warningDeleteCustomer(event, name, id)}
                                  >
                                    <Tooltip title={<Trans>Delete customer</Trans>}>
                                      <Delete />
                                    </Tooltip>
                                  </BaseActionsButton>
                                )}
                              </Can>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
              <CustomTablePagination
                rowsPerPageOptions={this.props.globalSettings.rowsPerPageOptions}
                count={this.props.total}
                rowsPerPage={this.props.globalSettings.itemsPerPage}
                page={page}
                changePage={this.handleChangePage}
                changeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

export default compose(
  withStyles(customerPageStyle),
  connect((store) => {
    return {
      CustomerListFilters: store.AppFilterStates.customersListFilter,
      customers: store.Customers.data,
      total: store.Customers.total,
      permissions: store.Permissions.data,
      globalSettings: store.GlobalSettings,
    };
  }, mapDispatchToProps)
)(CustomersPage);
