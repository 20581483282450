import {HttpClient} from '_api/httpClient';
import {API_ENDPOINT_MAP} from '_api/endpoints';
import {updateGlobalSettings} from '_actions/global/globalSettingsActions';
import '_assets/projectSpecific/fonts/fonts.css';
import ActivateBg from '_assets/projectSpecific/img/ec24-activate.jpg';
import Logo from '_assets/projectSpecific/img/ec24-logo-light.svg';
import RegularButton from '_components/global/Buttons/Button';
import Footer from '_components/global/Footer/Footer';
import GridContainer from '_components/global/Grid/GridContainer';
import GridItem from '_components/global/Grid/GridItem';
import activationStyle from '_components/projectSpecific/Activation/activationStyle';
import {AVAILABLE_LANGUAGES} from '_config/configConstants';
import {fireSuccessToast} from '_helpers/global/functions';
import i18nProvider from '_helpers/global/i18n';
import {parse} from 'query-string';
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {bindActionCreators} from 'redux';
import {Trans, t} from '@lingui/macro';
import {InputBase} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withStyles} from '@material-ui/core/styles';
import {activationErrorHandle} from './helpers';

const ActivationPage = (props) => {
  const {classes, updateGlobalSettings} = props;
  const {username, language} = parse(window.location.search);

  const [usernameState, setUsernameState] = useState(username || '');
  const [submitting, setSubmitting] = useState(false);

  function postActivation() {
    setSubmitting(true);
    return HttpClient.post(`${API_ENDPOINT_MAP.activation}`, {username: usernameState})
      .then(() => fireSuccessToast(<Trans>Link has been sent to your e-mail</Trans>))
      .catch(activationErrorHandle)
      .finally(() => {
        setSubmitting(false);
      });
  }

  useEffect(() => {
    if (AVAILABLE_LANGUAGES.includes(language)) {
      updateGlobalSettings({currentLanguage: language});
    } else {
      // if not specified, set language as 'de'
      updateGlobalSettings({currentLanguage: 'de'});
    }
  }, [language, updateGlobalSettings]);

  return (
    <GridContainer direction={'column'} className={classes.activationContainer}>
      <GridContainer direction={'row'} className={classes.blackContainer}>
        <GridItem container xs={7} noPadding={true}>
          <GridItem xs={12} style={{maxHeight: '100px'}}>
            <img
              src={Logo}
              alt={'logo'}
              style={{maxHeight: '50px', boxSizing: 'content-box', paddingTop: '40px'}}
            />
          </GridItem>
          <GridItem
            xs={12}
            style={{
              color: 'white',
              fontSize: '28px',
              fontFamily: 'SharpGrotesk',
              position: 'relative',
              marginBottom: '20px',
            }}
          >
            <div className={classes.activationText}>
              <Trans>Activate your ec24 4.0 account</Trans>
            </div>
          </GridItem>
        </GridItem>
        <GridItem xs={5} style={{maxHeight: '100%', textAlign: 'right'}} noPadding={true}>
          <img
            src={ActivateBg}
            alt={'background'}
            style={{maxHeight: '100%', maxWidth: '100%', height: 'auto'}}
          />
        </GridItem>
      </GridContainer>

      <GridContainer
        direction={'row'}
        className={classes.formContainer}
        alignContent={'flex-start'}
      >
        <GridItem
          xs={12}
          style={{color: 'black', fontSize: '18px', fontWeight: 400, fontFamily: 'Campton'}}
        >
          <Trans>
            Thank you for your interest in ec24 4.0! In order to activate your account, we ask you
            to set a new password that complies with our updated policy. Enter your existing ec24
            username and click the button below to receive your password reset link.
          </Trans>
          <br />
          <br />
          <div style={{fontWeight: 600}}>
            <Trans>
              Please note that your new password will be valid for both ec24 Maxi (
              <a
                style={{
                  color: 'black',
                  textDecoration: 'underline',
                  textDecorationColor: '#085CF0',
                }}
                href={'https://maxi.ec24.ch/'}
                target={'_blank'}
                rel="noopener noreferrer"
              >
                maxi.ec24.ch
              </a>
              ) and ec24 4.0.
            </Trans>
          </div>
          <br />
          <br />
          <Trans>
            We would like to thank you for helping us to provide an even more secure user
            experience.
          </Trans>
        </GridItem>
        <GridItem xs={12} style={{marginTop: '30px'}}>
          <InputBase
            size={'small'}
            margin={'dense'}
            variant={'outlined'}
            className={classes.activationField}
            name={'username'}
            id={'username'}
            value={usernameState}
            onChange={(e) => setUsernameState(e.target.value)}
            placeholder={i18nProvider._(t`Username`)}
            disabled={submitting}
          />
        </GridItem>
        <GridItem xs={12}>
          <RegularButton
            className={classes.activationButton}
            disabled={!usernameState}
            onClick={postActivation}
          >
            {submitting ? (
              <CircularProgress className={classes.activationLoading} color="inherit" />
            ) : (
              <Trans>Register now</Trans>
            )}
          </RegularButton>
        </GridItem>
      </GridContainer>

      <GridContainer direction={'row'} className={classes.footerContainer}>
        <Footer />
      </GridContainer>
    </GridContainer>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateGlobalSettings,
    },
    dispatch
  );
};

export default compose(
  connect((store) => {
    return {};
  }, mapDispatchToProps),
  withStyles(activationStyle)
)(ActivationPage);
