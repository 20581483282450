import {updateGlobalSettings} from '_actions/global/globalSettingsActions';
import {updateLoader} from '_actions/global/loaderActions';
import {getBranches} from '_actions/projectSpecific/branchesActions';
import {getGroups} from '_actions/projectSpecific/groupsActions';
import {createUser, deleteAccount, editUser, getUsers} from '_actions/projectSpecific/usersActions';
import {accountsPageStyle, iconTheme} from '_assets/projectSpecific/jss/views/accountsPageStyle';
import BaseActionsButton from '_components/global/Buttons/BaseActionsButton';
import Card from '_components/global/Card/Card';
import CardBody from '_components/global/Card/CardBody';
import CardHeader from '_components/global/Card/CardHeader';
import CardIcon from '_components/global/Card/CardIcon';
import GridContainer from '_components/global/Grid/GridContainer';
import GridItem from '_components/global/Grid/GridItem';
import WarningAlert from '_components/global/SweetAlerts/WarningSweetAlert';
import CustomTablePagination from '_components/global/Tables/CustomTablePagination';
import SimpleTableHead from '_components/global/Tables/SimpleTableHead';
import EditAccountDialog from '_components/projectSpecific/Dialogs/EditAccountDialog';
import InsertAccountDialog from '_components/projectSpecific/Dialogs/InsertAccountDialog';
import AccountsTableToolbar from '_components/projectSpecific/Toolbars/AccountsTableToolbar';
import authorizedAbility from '_helpers/global/Authorization';
import {Can} from '_helpers/global/AuthorizedAbilityContext';
import {
  fireErrorToast,
  fireSuccessToast,
  getComputedOrderBy,
  prepareSortingState,
} from '_helpers/global/functions';
import {browserHistoryAccessor} from 'App/history';
import cx from 'classnames';
import {diff} from 'deep-object-diff';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {bindActionCreators} from 'redux';
import {Trans} from '@lingui/macro';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import {MuiThemeProvider, withStyles} from '@material-ui/core/styles';
import {AccountCircle, Block, CheckCircleOutline, Delete, Edit} from '@material-ui/icons';

const rows = [
  {
    id: 'name',
    label: <Trans>Name</Trans>,
    sortable: true,
  },
  {
    id: 'username',
    label: <Trans>Login name</Trans>,
    sortable: true,
  },
  {
    id: 'groups',
    label: <Trans>Role</Trans>,
    sortable: true,
  },
  {
    id: 'email',
    label: <Trans>Email</Trans>,
    sortable: true,
  },
  {
    id: 'enable_notification',
    label: <Trans>Send notifications</Trans>,
    sortable: false,
  },
  {
    id: 'actions',
    label: <Trans>Actions</Trans>,
    sortable: false,
  },
];

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getUsers,
      updateLoader,
      editUser,
      updateGlobalSettings,
      deleteAccount,
      getBranches,
      getGroups,
      createUser,
    },
    dispatch
  );
};

class AccountsPage extends Component {
  toastId = null;

  constructor(props) {
    super(props);
    this.state = {
      order: 'asc',
      page: 0,
      orderBy: null,
      openInsert: false,
      openEdit: false,
      initBranchesGroupsOptions: false,
      currentUser: null,
      alert: null,
    };
    if (authorizedAbility.cannot('read', 'Accounts')) {
      browserHistoryAccessor.push('/admin/404');
    }
  }

  componentDidMount = () => {
    this.fetchUsers();
  };

  fetchUsers = () => {
    this.props.updateLoader({globalLoading: true});
    return this.props
      .getUsers(
        this.state.page * this.props.globalSettings.itemsPerPage,
        this.props.globalSettings.itemsPerPage,
        getComputedOrderBy(this.state.orderBy, this.state.order)
      )
      .then(() => {
        this.props.updateLoader({globalLoading: false});
      });
  };

  fetchBranches = () => {
    this.props.getBranches();
  };

  fetchGroups = () => {
    this.props.getGroups();
  };

  handleRequestSort = (event, property) => {
    this.setState(prepareSortingState(this.state.orderBy, this.state.order, property), () =>
      this.fetchUsers()
    );
  };

  handleChangePage = (event, page, value) => {
    this.setState({page, value}, () => this.fetchUsers());
  };

  handleChangeRowsPerPage = (event) => {
    this.props.updateGlobalSettings({itemsPerPage: event.target.value}).then(() => {
      this.setState({page: 0}, () => this.fetchUsers());
    });
  };

  warningDeleteAccount = (event, name, id) => {
    this.setState({
      alert: (
        <WarningAlert
          title={<Trans>Are you sure?</Trans>}
          confirmCallback={(e) => this.confirmDeleteAccount(e, name, id)}
          closeCallback={() => this.hideAlert()}
          confirmBtnText={<Trans>Yes, delete!</Trans>}
          showCancel={true}
        >
          <Trans>You will delete the account of {name}!</Trans>
        </WarningAlert>
      ),
    });
  };

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  confirmDeleteAccount = (event, name, id) => {
    this.props.updateLoader({globalLoading: true});
    return this.props
      .deleteAccount(id)
      .then(() => {
        this.setState(
          {
            alert: null,
          },
          () => this.fetchUsers()
        );
        this.toastId = fireSuccessToast(
          <Trans>Account {name ? name : ''} was successfully deleted.</Trans>
        );
      })
      .catch((error) => {
        this.setState({
          alert: null,
        });
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireErrorToast(error.errors._error, {autoClose: false});
      });
  };

  handleOpenAccountInsert = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!this.state.initBranchesGroupsOptions) {
      this.setState(
        {
          initBranchesGroupsOptions: true,
        },
        () => {
          this.fetchBranches();
          this.fetchGroups();
          this.openAccountInsertModal();
        }
      );
    } else {
      this.openAccountInsertModal();
    }
  };

  openAccountInsertModal = () => {
    this.setState({
      openInsert: true,
    });
  };

  handleCloseAccountInsert = () => {
    this.setState({
      initBranchesGroupsOptions: true,
      openInsert: false,
    });
  };

  insertNewUserAccount = (values) => {
    delete values.repeatPassword;
    if (values.branch) {
      values.branch = {
        pk: values.branch,
      };
    }
    if (values.groups) {
      values.groups = [{pk: values.groups}];
    }
    this.props.updateLoader({globalLoading: true});
    return this.props
      .createUser(values)
      .then(() => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireSuccessToast(<Trans>User account successfully created.</Trans>);
        this.handleCloseAccountInsert();
        this.fetchUsers();
      })
      .catch((error) => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireErrorToast(error.errors._error, {autoClose: false});
        this.handleCloseAccountInsert();
      });
  };

  handleOpenAccountEdit = (e, user) => {
    e.preventDefault();
    e.stopPropagation();
    if (!this.state.initBranchesGroupsOptions) {
      this.setState(
        {
          initBranchesGroupsOptions: true,
        },
        () => {
          this.fetchBranches();
          this.fetchGroups();
          this.openAccountEditModal(user);
        }
      );
    } else {
      this.openAccountEditModal(user);
    }
  };

  openAccountEditModal = (user) => {
    this.setState({
      openEdit: true,
      currentUser: user,
    });
  };

  handleCloseAccountEdit = () => {
    this.setState({
      openEdit: false,
      currentUser: null,
    });
  };

  updateUserAccount = (values) => {
    delete values.repeatPassword;
    const newUser = diff(this.state.currentUser, values);
    if (newUser.branch) {
      newUser.branch = {
        pk: newUser.branch,
      };
    }
    if (newUser.groups) {
      newUser.groups = [{pk: newUser.groups}];
    }
    if (newUser.auth_type === true) {
      newUser.auth_type = 'oracle';
    } else {
      newUser.auth_type = 'local';
    }
    this.props.updateLoader({globalLoading: true});
    return this.props
      .editUser(this.state.currentUser.id, newUser)
      .then(() => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireSuccessToast(<Trans>New account was successfully edited.</Trans>);
        this.handleCloseAccountEdit();
        this.fetchUsers();
      })
      .catch((error) => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireErrorToast(error.errors._error, {autoClose: false});
        this.handleCloseAccountEdit();
      });
  };

  getOptions = (data) => {
    const listOptions = [];
    data.forEach((obj) => {
      const objectOption = {};
      objectOption.value = obj.id;
      objectOption.label = obj.name;
      listOptions.push(objectOption);
    });
    return listOptions;
  };

  getInitUserValues = (user) => {
    const initValues = {...user};
    if (user.branch) {
      initValues.branch = user.branch.id;
    }
    if (user.groups.length && Array.isArray(user.groups)) {
      initValues.groups = user.groups[0].id;
    }
    if (user.auth_type === 'oracle') {
      initValues.auth_type = true;
    } else {
      initValues.auth_type = false;
    }
    return initValues;
  };

  render() {
    const {classes} = this.props;
    const {order, page, orderBy} = this.state;
    return (
      <GridContainer>
        {this.state.alert}
        <GridItem xs={12}>
          <Card>
            <CardHeader color="blue" icon>
              <CardIcon color="blue">
                <AccountCircle />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                <Trans>Accounts</Trans>
              </h4>
            </CardHeader>
            <CardBody>
              <AccountsTableToolbar openInsert={(e) => this.handleOpenAccountInsert(e)} />
              <InsertAccountDialog
                open={this.state.openInsert}
                handleClose={this.handleCloseAccountInsert}
                branchesOptions={this.getOptions(this.props.branches)}
                roleOptions={this.getOptions(this.props.groups)}
                onSubmit={this.insertNewUserAccount}
              />
              <EditAccountDialog
                open={this.state.openEdit}
                handleClose={this.handleCloseAccountEdit}
                branchesOptions={this.getOptions(this.props.branches)}
                roleOptions={this.getOptions(this.props.groups)}
                initValues={
                  this.state.currentUser && this.getInitUserValues(this.state.currentUser)
                }
                onSubmit={this.updateUserAccount}
              />
              <div className={classes.tableWrapper}>
                <Table className={classes.table} aria-labelledby="tableTitle">
                  <SimpleTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={this.handleRequestSort}
                    rowCount={this.props.users.length}
                    rows={rows}
                  />
                  <TableBody>
                    {this.props.users.map((user) => {
                      const {id, name, username, groups, email, enable_notification} = user;

                      return (
                        <TableRow hover tabIndex={-1} key={id}>
                          <TableCell classes={{root: classes.cell}}>{name}</TableCell>
                          <TableCell classes={{root: classes.cell}}>{username}</TableCell>
                          <TableCell classes={{root: classes.cell}}>
                            {groups && Array.isArray(groups) && groups.length === 1
                              ? groups[0].name
                              : ''}
                          </TableCell>
                          <TableCell classes={{root: classes.cell}}>{email}</TableCell>
                          <TableCell classes={{root: classes.cell}}>
                            {enable_notification ? (
                              <MuiThemeProvider theme={iconTheme}>
                                <CheckCircleOutline
                                  color="secondary"
                                  className={classes.notificationIcon}
                                />
                              </MuiThemeProvider>
                            ) : (
                              <Block color="secondary" className={classes.notificationIcon} />
                            )}
                          </TableCell>
                          <TableCell classes={{root: classes.cell}}>
                            <div className={cx('actions-right', classes.actions)}>
                              <Can I="update" a="Accounts">
                                <BaseActionsButton
                                  color="success"
                                  onClick={(e) => this.handleOpenAccountEdit(e, user)}
                                >
                                  <Tooltip title={<Trans>Edit user</Trans>}>
                                    <Edit />
                                  </Tooltip>
                                </BaseActionsButton>
                              </Can>
                              <Can I="delete" a="Accounts">
                                <BaseActionsButton
                                  color="danger"
                                  onClick={(event) => this.warningDeleteAccount(event, name, id)}
                                >
                                  <Tooltip title={<Trans>Delete account</Trans>}>
                                    <Delete />
                                  </Tooltip>
                                </BaseActionsButton>
                              </Can>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
              <CustomTablePagination
                rowsPerPageOptions={this.props.globalSettings.rowsPerPageOptions}
                count={this.props.total}
                rowsPerPage={this.props.globalSettings.itemsPerPage}
                page={page}
                changePage={this.handleChangePage}
                changeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

export default compose(
  withStyles(accountsPageStyle),
  connect((store) => {
    return {
      users: store.Users.data,
      total: store.Users.total,
      globalSettings: store.GlobalSettings,
      permissions: store.Permissions.data,
      branches: store.Branches.data,
      groups: store.Groups.data,
    };
  }, mapDispatchToProps)
)(AccountsPage);
