import {
  permissionsFormStyle,
  tableHeadTheme,
} from '_assets/projectSpecific/jss/components/Forms/permissionsFormStyle';
import Button from '_components/global/Buttons/Button';
import {FormCheckbox} from '_components/global/Form/FormFields';
import SimpleTableHead from '_components/global/Tables/SimpleTableHead';
import cx from 'classnames';
import React from 'react';
import compose from 'recompose/compose';
import {Field, reduxForm} from 'redux-form';
import {Trans} from '@lingui/macro';
import {MuiThemeProvider} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {withStyles} from '@material-ui/core/styles';

const PermissionsForm = ({
  handleSubmit,
  pristine,
  submitting,
  form,
  rows2,
  classes,
  options,
  reset,
}) => {
  return (
    <form onSubmit={handleSubmit} className={classes.margin}>
      <div className={cx(classes.tableWrapper, classes.marginBottom)}>
        <Table className={classes.table} aria-labelledby="tableTitle">
          <MuiThemeProvider theme={tableHeadTheme}>
            <SimpleTableHead rows={rows2} />
          </MuiThemeProvider>
          <TableBody>
            {options.map((option, index) => {
              return (
                <TableRow hover tabIndex={-1} key={index}>
                  <TableCell classes={{root: classes.cell}}>
                    <Field
                      name={`options[${option.id}]`}
                      id={`options[${option.id}]`}
                      component={FormCheckbox}
                      typeTable={true}
                    />
                  </TableCell>
                  <TableCell classes={{root: classes.cell}}>{option.codename}</TableCell>
                  <TableCell classes={{root: classes.cell}}>{option.name}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <div className={classes.formActions}>
        <Button onClick={reset} className={cx(classes.button)} disabled={pristine || submitting}>
          <Trans>Reset</Trans>
        </Button>
        <Button color={'primary'} disabled={pristine || submitting} type="submit">
          <Trans>Save changes</Trans>
        </Button>
      </div>
    </form>
  );
};

export default compose(
  reduxForm({
    enableReinitialize: true,
  }),
  withStyles(permissionsFormStyle)
)(PermissionsForm);
