import {updateGlobalSettings} from '_actions/global/globalSettingsActions';
import {updateLoader} from '_actions/global/loaderActions';
import {getLostSaleReason} from '_actions/projectSpecific/lostSaleResonActions';
import {lostSaleReasonPageStyle} from '_assets/projectSpecific/jss/views/lostSaleReasonPageStyle';
import Card from '_components/global/Card/Card';
import CardBody from '_components/global/Card/CardBody';
import CardHeader from '_components/global/Card/CardHeader';
import CardIcon from '_components/global/Card/CardIcon';
import GridContainer from '_components/global/Grid/GridContainer';
import GridItem from '_components/global/Grid/GridItem';
import CustomTablePagination from '_components/global/Tables/CustomTablePagination';
import SimpleTableHead from '_components/global/Tables/SimpleTableHead';
import FilterLostSaleReasonForm from '_components/projectSpecific/Forms/FilterLostSaleReasonForm';
import {DATE_TIME_FORMAT} from '_config/configConstants';
import authorizedAbility from '_helpers/global/Authorization';
import {getComputedOrderBy, prepareSortingState} from '_helpers/global/functions';
import {browserHistoryAccessor} from 'App/history';
import moment from 'moment';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {bindActionCreators} from 'redux';
import {Trans} from '@lingui/macro';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {withStyles} from '@material-ui/core/styles';
import {RemoveShoppingCart} from '@material-ui/icons';

const rows = [
  {
    id: 'username',
    label: <Trans>Username</Trans>,
    sortable: true,
  },
  {
    id: 'productNo',
    label: <Trans>Product NO.</Trans>,
    sortable: true,
  },
  {
    id: 'created',
    label: <Trans>Date of lost sale</Trans>,
    sortable: true,
  },
  {
    id: 'reason',
    label: <Trans>Reason</Trans>,
    sortable: false,
  },
];

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateLoader,
      updateGlobalSettings,
      getLostSaleReason,
    },
    dispatch
  );
};

class LostSaleReasonPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: 'asc',
      page: 0,
      orderBy: this.props.LostSaleReasonListFilters.orderBy,
      username: this.props.LostSaleReasonListFilters.username,
      productNo: this.props.LostSaleReasonListFilters.productNo,
    };
    if (authorizedAbility.cannot('read', 'LostSaleReason')) {
      browserHistoryAccessor.push('/admin/404');
    }
  }

  componentDidMount = () => {
    this.fetchLostSaleReasons();
  };

  fetchLostSaleReasons = () => {
    this.props.updateLoader({globalLoading: true});
    return this.props
      .getLostSaleReason(
        this.state.page * this.props.globalSettings.itemsPerPage,
        this.props.globalSettings.itemsPerPage,
        getComputedOrderBy(this.state.orderBy, this.state.order),
        this.state.username,
        this.state.productNo
      )
      .then(() => {
        this.props.updateLoader({globalLoading: false});
      });
  };

  handleRequestSort = (event, property) => {
    this.setState(prepareSortingState(this.state.orderBy, this.state.order, property), () =>
      this.fetchLostSaleReasons()
    );
  };

  handleChangePage = (event, page, value) => {
    this.setState({page, value}, () => this.fetchLostSaleReasons());
  };

  handleChangeRowsPerPage = (event) => {
    this.props.updateGlobalSettings({itemsPerPage: event.target.value}).then(() => {
      this.setState({page: 0}, () => this.fetchLostSaleReasons());
    });
  };

  filterLostSaleReasons = (values) => {
    this.setState(
      {
        username: values.username,
        productNo: values.product_no,
      },
      () => this.fetchLostSaleReasons()
    );
  };

  handleResetLostSaleReasonFiltering = () => {
    this.setState(
      {
        username: null,
        productNo: null,
      },
      () => this.fetchLostSaleReasons()
    );
  };

  render() {
    const {classes} = this.props;
    const {order, page, orderBy} = this.state;
    return (
      <GridContainer>
        {this.state.alert}
        <GridItem xs={12}>
          <Card>
            <CardHeader color="blue" icon>
              <CardIcon color="blue">
                <b>
                  <Trans>Filter</Trans>
                </b>
              </CardIcon>
            </CardHeader>
            <CardBody>
              <FilterLostSaleReasonForm
                form="filterLostSaleReasonsForm"
                initialValues={this.props.LostSaleReasonListFilters}
                onSubmit={this.filterLostSaleReasons}
                handleReset={this.handleResetLostSaleReasonFiltering}
              />
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12}>
          <Card>
            <CardHeader color="blue" icon>
              <CardIcon color="blue">
                <RemoveShoppingCart />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                <Trans>Lost Sale Reason</Trans>
              </h4>
            </CardHeader>
            <CardBody>
              <div className={classes.tableWrapper}>
                <Table className={classes.table} aria-labelledby="tableTitle">
                  <SimpleTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={this.handleRequestSort}
                    rowCount={this.props.lostSaleReasons.length}
                    rows={rows}
                  />
                  <TableBody>
                    {this.props.lostSaleReasons.map((item) => {
                      const {id, user, product_no, reason, created} = item;

                      return (
                        <TableRow hover tabIndex={-1} key={id}>
                          <TableCell classes={{root: classes.cell}}>
                            {user ? user.username : ''}
                          </TableCell>
                          <TableCell classes={{root: classes.cell}}>{product_no}</TableCell>
                          <TableCell classes={{root: classes.cell}}>
                            {moment(created).format(DATE_TIME_FORMAT)}
                          </TableCell>
                          <TableCell classes={{root: classes.cell}}>{reason}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
              <CustomTablePagination
                rowsPerPageOptions={this.props.globalSettings.rowsPerPageOptions}
                count={this.props.total}
                rowsPerPage={this.props.globalSettings.itemsPerPage}
                page={page}
                changePage={this.handleChangePage}
                changeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

export default compose(
  withStyles(lostSaleReasonPageStyle),
  connect((store) => {
    return {
      LostSaleReasonListFilters: store.AppFilterStates.lostSaleReasonListFilter,
      lostSaleReasons: store.LostSaleReasons.data,
      total: store.LostSaleReasons.total,
      globalSettings: store.GlobalSettings,
    };
  }, mapDispatchToProps)
)(LostSaleReasonPage);
