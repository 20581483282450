// CARTS
export const FETCH_CARTS_REQUEST = 'FETCH_CARTS_REQUEST';
export const FETCH_CARTS_SUCCESS = 'FETCH_CARTS_SUCCESS';
export const FETCH_CARTS_FAIL = 'FETCH_CARTS_FAIL';

// CART
export const FETCH_CART_REQUEST = 'FETCH_CART_REQUEST';
export const FETCH_CART_SUCCESS = 'FETCH_CART_SUCCESS';
export const FETCH_CART_FAIL = 'FETCH_CART_FAIL';

// CART_LOCK_INFO
export const FETCH_CART_LOCK_INFO_REQUEST = 'FETCH_CART_LOCK_INFO_REQUEST';
export const FETCH_CART_LOCK_INFO_SUCCESS = 'FETCH_CART_LOCK_INFO_SUCCESS';
export const FETCH_CART_LOCK_INFO_FAIL = 'FETCH_CART_LOCK_INFO_FAIL';

// DELIVERY_OPTIONS
export const FETCH_CUSTOMER_DELIVERY_OPTIONS_REQUEST = 'FETCH_CUSTOMER_DELIVERY_OPTIONS_REQUEST';
export const FETCH_CUSTOMER_DELIVERY_OPTIONS_SUCCESS = 'FETCH_CUSTOMER_DELIVERY_OPTIONS_SUCCESS';
export const FETCH_CUSTOMER_DELIVERY_OPTIONS_FAIL = 'FETCH_CUSTOMER_DELIVERY_OPTIONS_FAIL';
export const FETCH_DELIVERY_OPTIONS_REQUEST = 'FETCH_DELIVERY_OPTIONS_REQUEST';
export const FETCH_DELIVERY_OPTIONS_SUCCESS = 'FETCH_DELIVERY_OPTIONS_SUCCESS';
export const FETCH_DELIVERY_OPTIONS_FAIL = 'FETCH_DELIVERY_OPTIONS_FAIL';
export const FETCH_MY_DELIVERY_OPTIONS_REQUEST = 'FETCH_MY_DELIVERY_OPTIONS_REQUEST';
export const FETCH_MY_DELIVERY_OPTIONS_SUCCESS = 'FETCH_MY_DELIVERY_OPTIONS_SUCCESS';
export const FETCH_MY_DELIVERY_OPTIONS_FAIL = 'FETCH_MY_DELIVERY_OPTIONS_FAIL';
export const FETCH_DELIVERY_OPTIONS_MEMBERSHIP_REQUEST =
  'FETCH_DELIVERY_OPTIONS_MEMBERSHIP_MEMBERSHIP_REQUEST';
export const FETCH_DELIVERY_OPTIONS_MEMBERSHIP_SUCCESS =
  'FETCH_DELIVERY_OPTIONS_MEMBERSHIP_SUCCESS';
export const FETCH_DELIVERY_OPTIONS_MEMBERSHIP_FAIL = 'FETCH_DELIVERY_OPTIONS_MEMBERSHIP_FAIL';

// USERS
export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAIL = 'FETCH_USERS_FAIL';
export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAIL = 'FETCH_USER_FAIL';
export const FETCH_USER_PERMISSIONS_REQUEST = 'FETCH_USER_PERMISSIONS_REQUEST';
export const FETCH_USER_PERMISSIONS_SUCCESS = 'FETCH_USER_PERMISSIONS_SUCCESS';
export const FETCH_USER_PERMISSIONS_FAIL = 'FETCH_USER_PERMISSIONS_FAIL';

// CUSTOMERS
export const FETCH_CUSTOMERS_REQUEST = 'FETCH_CUSTOMERS_REQUEST';
export const FETCH_CUSTOMERS_SUCCESS = 'FETCH_CUSTOMERS_SUCCESS';
export const FETCH_CUSTOMERS_FAIL = 'FETCH_CUSTOMERS_FAIL';
export const FETCH_CUSTOMER_REQUEST = 'FETCH_CUSTOMER_REQUEST';
export const FETCH_CUSTOMER_SUCCESS = 'FETCH_CUSTOMER_SUCCESS';
export const FETCH_CUSTOMER_FAIL = 'FETCH_CUSTOMER_FAIL';
export const FETCH_CUSTOMERS_DELIVERY_OPTIONS_REQUEST = 'FETCH_CUSTOMERS_DELIVERY_OPTIONS_REQUEST';
export const FETCH_CUSTOMERS_DELIVERY_OPTIONS_SUCCESS = 'FETCH_CUSTOMERS_DELIVERY_OPTIONS_SUCCESS';
export const FETCH_CUSTOMERS_DELIVERY_OPTIONS_FAIL = 'FETCH_CUSTOMERS_DELIVERY_OPTIONS_FAIL';
export const FETCH_MY_CUSTOMER_REQUEST = 'FETCH_MY_CUSTOMER_REQUEST';
export const FETCH_MY_CUSTOMER_SUCCESS = 'FETCH_MY_CUSTOMER_SUCCESS';
export const FETCH_MY_CUSTOMER_FAIL = 'FETCH_MY_CUSTOMER_FAIL';
export const FETCH_CUSTOMERS_IN_DISCOUNT_GROUPS_REQUEST =
  'FETCH_CUSTOMERS_IN_DISCOUNT_GROUPS_REQUEST';
export const FETCH_CUSTOMERS_IN_DISCOUNT_GROUPS_SUCCESS =
  'FETCH_CUSTOMERS_IN_DISCOUNT_GROUPS_SUCCESS';
export const FETCH_CUSTOMERS_IN_DISCOUNT_GROUPS_FAIL = 'FETCH_CUSTOMERS_IN_DISCOUNT_GROUPS_FAIL';
export const FETCH_CUSTOMERS_IN_DISCOUNT_GROUPS_INSERT_REQUEST =
  'FETCH_CUSTOMERS_IN_DISCOUNT_GROUPS_INSERT_REQUEST';
export const FETCH_CUSTOMERS_IN_DISCOUNT_GROUPS_INSERT_SUCCESS =
  'FETCH_CUSTOMERS_IN_DISCOUNT_GROUPS_INSERT_SUCCESS';
export const FETCH_CUSTOMERS_IN_DISCOUNT_GROUPS_INSERT_FAIL =
  'FETCH_CUSTOMERS_IN_DISCOUNT_GROUPS_INSERT_FAIL';

// BRANCHES
export const FETCH_BRANCHES_REQUEST = 'FETCH_BRANCHES_REQUEST';
export const FETCH_BRANCHES_SUCCESS = 'FETCH_BRANCHES_SUCCESS';
export const FETCH_BRANCHES_FAIL = 'FETCH_BRANCHES_FAIL';

// PERMISSIONS
export const FETCH_PERMISSIONS_REQUEST = 'FETCH_PERMISSIONS_REQUEST';
export const FETCH_PERMISSIONS_SUCCESS = 'FETCH_PERMISSIONS_SUCCESS';
export const FETCH_PERMISSIONS_FAIL = 'FETCH_PERMISSIONS_FAIL';

// GROUPS
export const FETCH_GROUPS_REQUEST = 'FETCH_GROUPS_REQUEST';
export const FETCH_GROUPS_SUCCESS = 'FETCH_GROUPS_SUCCESS';
export const FETCH_GROUPS_FAIL = 'FETCH_GROUPS_FAIL';

// LOST SALE REASON
export const FETCH_LOST_SALE_REASON_REQUEST = 'FETCH_LOST_SALE_REASON_REQUEST';
export const FETCH_LOST_SALE_REASON_SUCCESS = 'FETCH_LOST_SALE_REASON_SUCCESS';
export const FETCH_LOST_SALE_REASON_FAIL = 'FETCH_LOST_SALE_REASON_FAIL';

// LAST LOGIN
export const FETCH_LAST_LOGIN_REQUEST = 'FETCH_LAST_LOGIN_REQUEST';
export const FETCH_LAST_LOGIN_SUCCESS = 'FETCH_LAST_LOGIN_SUCCESS';
export const FETCH_LAST_LOGIN_FAIL = 'FETCH_LAST_LOGIN_FAIL';

// DISCOUNT CATEGORIES
export const FETCH_DISCOUNT_CATEGORIES_REQUEST = 'FETCH_DISCOUNT_CATEGORIES_REQUEST';
export const FETCH_DISCOUNT_CATEGORIES_SUCCESS = 'FETCH_DISCOUNT_CATEGORIES_SUCCESS';
export const FETCH_DISCOUNT_CATEGORIES_FAIL = 'FETCH_DISCOUNT_CATEGORIES_FAIL';
export const FETCH_DISCOUNT_CATEGORIES_IMPORT_REQUEST = 'FETCH_DISCOUNT_CATEGORIES_IMPORT_REQUEST';
export const FETCH_DISCOUNT_CATEGORIES_IMPORT_SUCCESS = 'FETCH_DISCOUNT_CATEGORIES_IMPORT_SUCCESS';
export const FETCH_DISCOUNT_CATEGORIES_IMPORT_FAIL = 'FETCH_DISCOUNT_CATEGORIES_IMPORT_FAIL';

// DISCOUNT GROUPS
export const FETCH_DISCOUNT_GROUPS_REQUEST = 'FETCH_DISCOUNT_GROUPS_REQUEST';
export const FETCH_DISCOUNT_GROUPS_SUCCESS = 'FETCH_DISCOUNT_GROUPS_SUCCESS';
export const FETCH_DISCOUNT_GROUPS_FAIL = 'FETCH_DISCOUNT_GROUPS_FAIL';

// DISCOUNT LIMIT CODES
export const FETCH_DISCOUNT_LIMIT_CODES_REQUEST = 'FETCH_DISCOUNT_LIMIT_CODES_REQUEST';
export const FETCH_DISCOUNT_LIMIT_CODES_SUCCESS = 'FETCH_DISCOUNT_LIMIT_CODES_SUCCESS';
export const FETCH_DISCOUNT_LIMIT_CODES_FAIL = 'FETCH_DISCOUNT_LIMIT_CODES_FAIL';

// CONFIG
export const FETCH_CONFIG_REQUEST = 'FETCH_CONFIG_REQUEST';
export const FETCH_CONFIG_SUCCESS = 'FETCH_CONFIG_SUCCESS';
export const FETCH_CONFIG_FAIL = 'FETCH_CONFIG_FAIL';
export const FETCH_WAITING_REQUEST = 'FETCH_WAITING_REQUEST';
export const FETCH_WAITING_SUCCESS = 'FETCH_WAITING_SUCCESS';
export const FETCH_WAITING_FAIL = 'FETCH_WAITING_FAIL';
export const POST_WAITING_REQUEST = 'POST_WAITING_REQUEST';
export const POST_WAITING_SUCCESS = 'POST_WAITING_SUCCESS';
export const POST_WAITING_FAIL = 'POST_WAITING_FAIL';

// WHOLESALER AVAILABILITY
export const FETCH_WHOLESALER_HISTORY_REQUEST = 'FETCH_WHOLESALER_HISTORY_REQUEST';
export const FETCH_WHOLESALER_HISTORY_SUCCESS = 'FETCH_WHOLESALER_HISTORY_SUCCESS';
export const FETCH_WHOLESALER_HISTORY_FAIL = 'FETCH_WHOLESALER_HISTORY_FAIL';

// VEHICLE REFERENCES
export const FETCH_VR_S_REQUEST = 'FETCH_VR_S_REQUEST';
export const FETCH_VR_S_SUCCESS = 'FETCH_VR_S_SUCCESS';
export const FETCH_VR_S_FAIL = 'FETCH_VR_S_FAIL';

export const FETCH_VR_T_REQUEST = 'FETCH_VR_T_REQUEST';
export const FETCH_VR_T_SUCCESS = 'FETCH_VR_T_SUCCESS';
export const FETCH_VR_T_FAIL = 'FETCH_VR_T_FAIL';
