import {
  blueCardHeader,
  dangerCardHeader,
  grayColor60,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
  successCardHeader,
  warningCardHeader,
} from '_assets/global/jss/styleHelpers';

const cardIconStyle = {
  cardIcon: {
    '&$warningCardHeader,&$successCardHeader,&$dangerCardHeader,&$infoCardHeader,&$primaryCardHeader,&$roseCardHeader,&$blueCardHeader':
      {
        borderRadius: '3px',
        backgroundColor: grayColor60,
        padding: '15px',
        marginTop: '-20px',
        marginRight: '15px',
        float: 'left',
      },
  },
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
  blueCardHeader,
};

export default cardIconStyle;
