import {
  FETCH_VR_S_FAIL,
  FETCH_VR_S_REQUEST,
  FETCH_VR_S_SUCCESS,
  FETCH_VR_T_FAIL,
  FETCH_VR_T_REQUEST,
  FETCH_VR_T_SUCCESS,
} from '_actions/projectSpecific/types';

const initState = {
  stamnummer: {
    loading: false,
    total: 0,
    data: [],
  },
  typenscheinnummer: {
    loading: false,
    total: 0,
    data: [],
  },
};

export const VehicleReferencesReducer = (state = initState, action = null) => {
  switch (action.type) {
    case FETCH_VR_S_REQUEST: {
      return {
        ...state,
        stamnummer: {
          ...state.stamnummer,
          loading: true,
        },
      };
    }
    case FETCH_VR_S_SUCCESS: {
      return {
        ...state,
        stamnummer: {
          total: action.data.meta.total_count,
          loading: false,
          data: action.data.objects,
        },
      };
    }
    case FETCH_VR_S_FAIL: {
      return {
        ...state,
        stamnummer: {
          ...state.stamnummer,
          loading: false,
        },
      };
    }
    case FETCH_VR_T_REQUEST: {
      return {
        ...state,
        typenscheinnummer: {
          ...state.typenscheinnummer,
          loading: true,
        },
      };
    }
    case FETCH_VR_T_SUCCESS: {
      return {
        ...state,
        typenscheinnummer: {
          total: action.data.meta.total_count,
          loading: false,
          data: action.data.objects,
        },
      };
    }
    case FETCH_VR_T_FAIL: {
      return {
        ...state,
        typenscheinnummer: {
          ...state.typenscheinnummer,
          loading: false,
        },
      };
    }
    default:
      return state;
  }
};
