import {
  blueColor80,
  cardTitle,
  dangerColor,
  grayColor20,
  grayColor50,
  successColor,
} from '_assets/global/jss/styleHelpers';

export const editUserPageStyle = (theme) => ({
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400',
    },
  },
  unEditableInfo: {
    margin: '10px 0',
  },
  actionButtons: {
    position: 'absolute',
    top: '30px',
    right: '20px',
    width: 'auto',
    [theme.breakpoints.down('sm')]: {
      top: '60px',
    },
  },
  permissionsButton: {
    marginLeft: '10px',
  },
  tabBar: {
    marginTop: '30px',
  },
  step: {
    background: grayColor20,
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.3)',
    padding: '0px 20px',
  },
  backBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '-20px',
    marginRight: '10px',
  },

  configuration: {
    backgroundColor: grayColor20,
    padding: '20px',
    margin: '.3125rem 1px',
    boxShadow: '0px 4px 4px 0px rgb(0 0 0 / 30%)',
  },
  fieldPadding: {
    padding: '0 15px',
  },
  title: {
    padding: '5px 15px',
    fontSize: '16px',
    borderBottom: '2px solid ' + blueColor80,
    margin: '25px 0 15px 0',
    textTransform: 'uppercase',
    fontWeight: 600,
    color: blueColor80,
  },
  gridRowBorder: {
    borderBottom: '1px solid' + grayColor50,
  },
  totalWaitingOrdersText: {
    paddingRight: 10,
    fontWeight: 600,
  },
  lightGreenBackground: {
    backgroundColor: successColor[0],
  },
  lightRedBackground: {
    backgroundColor: dangerColor[0],
  },
  importProgressBar: {
    width: '100%',
  },
  importProgressText: {
    paddingBottom: '15px',
  },
});
