import {updateLoader} from '_actions/global/loaderActions';
import {getCustomer} from '_actions/projectSpecific/customersActions';
import {
  customerDetailPageStyle,
  iconTheme,
} from '_assets/projectSpecific/jss/views/customerDetailPageStyle';
import Button from '_components/global/Buttons/Button';
import Card from '_components/global/Card/Card';
import CardBody from '_components/global/Card/CardBody';
import CardHeader from '_components/global/Card/CardHeader';
import CardIcon from '_components/global/Card/CardIcon';
import GridContainer from '_components/global/Grid/GridContainer';
import GridItem from '_components/global/Grid/GridItem';
import Tag from '_components/global/Tags/Tag';
import CustomerDetailTable from '_components/projectSpecific/Tables/CustomerDetailTable';
import {PHONE_PREFIX} from '_config/configConstants';
import authorizedAbility from '_helpers/global/Authorization';
import {Can} from '_helpers/global/AuthorizedAbilityContext';
import {browserHistoryAccessor} from 'App/history';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import compose from 'recompose/compose';
import {bindActionCreators} from 'redux';
import {Trans} from '@lingui/macro';
import {MuiThemeProvider} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import {withStyles} from '@material-ui/core/styles';
import {ArrowBack, CheckCircle, Edit, RemoveCircle, Visibility} from '@material-ui/icons';

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCustomer,
      updateLoader,
    },
    dispatch
  );
};

class CustomerDetailPage extends Component {
  constructor(props) {
    super(props);
    if (authorizedAbility.cannot('read', 'Customers')) {
      browserHistoryAccessor.push('/admin/404');
    }
  }

  componentDidMount = () => {
    this.fetchCustomer();
  };

  fetchCustomer = () => {
    this.props.updateLoader({globalLoading: true});
    const {id} = this.props.match.params;
    return this.props.getCustomer(id).then(() => {
      this.props.updateLoader({globalLoading: false});
    });
  };

  getPrefix = (prefix) => {
    let phonePrefix = '';
    PHONE_PREFIX.forEach((option) => {
      if (prefix === option.value) {
        phonePrefix = option.label;
      }
    });
    return phonePrefix;
  };

  getTableData = (customer) => {
    return [
      {field: <Trans>Customer No.</Trans>, value: customer.customer_no_2},
      {field: <Trans>Customer ID</Trans>, value: customer.customer_no},
      {field: <Trans>Name</Trans>, value: customer.name_1},
      {field: '', value: customer.name_2},
      {
        field: <Trans>Role</Trans>,
        value: customer.main_user_groups.length && customer.main_user_groups[0].name,
      },
      {field: <Trans>Site ID</Trans>, value: customer.site_id},
      {
        field: <Trans>Address</Trans>,
        value: `${customer.address_1 ? customer.address_1 : ''} ${
          customer.address_2 ? customer.address_2 : ''
        }`,
      },
      {field: '', value: customer.address_3},
      {field: <Trans>Zip Code</Trans>, value: customer.post_code},
      {field: <Trans>City</Trans>, value: customer.city},
      {
        field: <Trans>Phone number</Trans>,
        value: `${this.getPrefix(customer.phone_prefix ? customer.phone_prefix : '')} ${
          customer.phone ? customer.phone : ''
        }`,
      },
      {field: <Trans>Auth type</Trans>, value: customer.main_user_auth_type},
      {field: <Trans>E-mail</Trans>, value: customer.email},
      {field: <Trans>Website</Trans>, value: customer.website},
      {field: <Trans>Login name</Trans>, value: customer.main_user_username},
      {
        field: <Trans>Discount group</Trans>,
        value: customer.discount_group ? customer.discount_group.name : '',
      },
      {
        field: <Trans>Permissions</Trans>,
        value:
          customer.main_user_permissions &&
          customer.main_user_permissions.map((permission) => (
            <Tag key={permission.id} content={permission.codename} />
          )),
      },
      {
        field: <Trans>Delivery terms</Trans>,
        value:
          customer.delivery_options &&
          customer.delivery_options.map((deliveryOption) => (
            <Tag key={deliveryOption.id} content={deliveryOption.name} />
          )),
      },
      {
        field: <Trans>Cooperation concept</Trans>,
        value: customer.is_coco ? (
          <MuiThemeProvider theme={iconTheme}>
            <CheckCircle color="secondary" />
          </MuiThemeProvider>
        ) : (
          <RemoveCircle color="secondary" />
        ),
      },
    ];
  };

  render() {
    const {classes} = this.props;
    const {id} = this.props.match.params;

    return (
      <GridContainer justify="center">
        <GridItem xs={12}>
          <Card>
            {!this.props.isCustomerLoading && (
              <>
                <CardHeader color="blue" icon>
                  <CardIcon color="blue">
                    <Visibility />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>
                    <Trans>Detail of {this.props.customer.name}</Trans>
                  </h4>
                  <div className={classes.backBtn}>
                    <Tooltip title={<Trans>Back to list</Trans>}>
                      <Button
                        className={classes.btnIcon}
                        onClick={() => browserHistoryAccessor.goBack()}
                      >
                        <ArrowBack />
                      </Button>
                    </Tooltip>
                    <Can I="update" a="Customers">
                      <Tooltip title={<Trans>Edit customer</Trans>}>
                        <Button
                          color="success"
                          className={classes.editProfileButton}
                          component={Link}
                          to={`/admin/customers/${id}/edit`}
                        >
                          <Edit />
                        </Button>
                      </Tooltip>
                    </Can>
                  </div>
                </CardHeader>
                <CardBody>
                  <GridContainer justify="center">
                    <GridItem xs={12}>
                      <CustomerDetailTable data={this.getTableData(this.props.customer)} />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </>
            )}
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

export default compose(
  withStyles(customerDetailPageStyle),
  connect((store) => {
    return {
      customer: store.Customer.data,
      isCustomerLoading: store.Customer.loading,
    };
  }, mapDispatchToProps)
)(CustomerDetailPage);
