import {cardTitle, grayColor20, successColor} from '_assets/global/jss/styleHelpers';
import {createMuiTheme} from '@material-ui/core/styles/index';

export const userProfilePageStyle = (theme) => ({
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    fontWeight: '700',
    marginTop: '15px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400',
    },
  },
  editProfileButton: {
    marginLeft: '5px',
  },
  flex: {
    display: 'flex',
  },
  detailItem: {
    minWidth: '200px',
    height: '30px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '150px',
    },
  },
  content: {
    fontSize: '14px',
    marginTop: '30px',
  },
  details: {
    marginTop: '40px',
  },
  tabBar: {
    marginTop: '30px',
  },
  step: {
    background: grayColor20,
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.3)',
    padding: '0px 20px 0px 20px',
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '15px 5px -20px 0px',
  },
  cardIconWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  activeIcon: {
    fontSize: '16px',
  },
});

export const iconTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    secondary: {
      main: successColor[0],
    },
  },
});

export const tabContainerTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiTabs: {
      flexContainer: {
        flexWrap: 'wrap',
      },
    },
  },
});
