import {globalDeliveryTermsFormStyle} from '_assets/projectSpecific/jss/components/Forms/globalDeliveryTermsFormStyle';
import Button from '_components/global/Buttons/Button';
import {FormSelectField, FormTextField} from '_components/global/Form/FormFields';
import GridContainer from '_components/global/Grid/GridContainer';
import GridItem from '_components/global/Grid/GridItem';
import {maxLength128, maxLength512, required} from '_helpers/global/FieldLevelValidation';
import cx from 'classnames';
import React from 'react';
import compose from 'recompose/compose';
import {Field, reduxForm} from 'redux-form';
import {Trans} from '@lingui/macro';
import {withStyles} from '@material-ui/core/styles';

const GlobalDeliveryOptionsForm = ({
  classes,
  handleSubmit,
  pristine,
  reset,
  submitting,
  form,
  submitName,
}) => {
  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <GridContainer justify="center">
        <GridItem xs={12} md={8}>
          <Field
            name="name"
            label={<Trans>Title</Trans>}
            id="name"
            component={FormTextField}
            validate={[required, maxLength128]}
            required
          />
        </GridItem>
        <GridItem xs={12} md={8}>
          <Field
            name="description"
            label={<Trans>Description</Trans>}
            id="description"
            component={FormTextField}
            validate={maxLength512}
          />
        </GridItem>
        <GridItem xs={12} md={8}>
          <Field
            name="price"
            label={<Trans>Price</Trans>}
            id="price"
            type="number"
            component={FormTextField}
            additionalInputProps={{step: 1}}
          />
        </GridItem>
        <GridItem xs={12} md={8}>
          <Field
            name="currency"
            label={<Trans>Currency</Trans>}
            id="currency"
            type="select"
            component={FormSelectField}
            options={['CHF']}
          />
        </GridItem>
        <GridItem xs={12}>
          <div className={classes.formActions}>
            <Button
              onClick={reset}
              className={cx(classes.button)}
              disabled={pristine || submitting}
            >
              <Trans>Reset</Trans>
            </Button>
            <Button color={'primary'} disabled={pristine || submitting} type="submit">
              {submitName}
            </Button>
          </div>
        </GridItem>
      </GridContainer>
    </form>
  );
};

export default compose(
  withStyles(globalDeliveryTermsFormStyle),
  reduxForm({
    enableReinitialize: true,
  })
)(GlobalDeliveryOptionsForm);
