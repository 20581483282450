import {formFieldStyle, textFieldTheme} from '_assets/global/jss/components/Fields/formFieldsStyle';
import ClearButton from '_components/global/Buttons/ClearButton';
import CustomCheckbox from '_components/global/Fields/CustomCheckbox';
import CustomChipMultipleSelect from '_components/global/Fields/CustomChipMultipleSelect';
import CustomSelect from '_components/global/Fields/CustomSelect';
import CustomSimpleSelect from '_components/global/Fields/CustomSimpleSelect';
import CustomSwitcher from '_components/global/Fields/CustomSwitcher';
import CustomDatePicker from '_components/global/Pickers/CustomDatePicker';
import CustomDateTimePicker from '_components/global/Pickers/CustomDatetimePicker';
import CustomTimePicker from '_components/global/Pickers/CustomTimePicker';
import React from 'react';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {MuiThemeProvider} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';

export const FormCheckbox = ({
  input,
  label,
  helpText,
  disabled,
  typeTable,
  meta: {touched, invalid, error},
  ...rest
}) => (
  <CustomCheckbox
    checked={!!input.value}
    label={label}
    error={touched && invalid}
    helpText={touched && error ? error : helpText}
    disabled={disabled}
    typeTable={typeTable}
    {...input}
    {...rest}
  />
);

export const FormSwitcher = ({
  input,
  label,
  helpText,
  disabled,
  meta: {touched, invalid, error},
  ...rest
}) => (
  <CustomSwitcher
    checked={!!input.value}
    label={label}
    error={touched && invalid}
    helpText={touched && error ? error : helpText}
    disabled={disabled}
    {...input}
    {...rest}
  />
);

export const FormDateTimeField = ({
  label,
  input,
  helpText,
  meta: {touched, invalid, error},
  ...rest
}) => (
  <CustomDateTimePicker
    dateTimeValue={input.value}
    onDateTimeChange={input.onChange}
    error={touched && invalid}
    helpText={touched && error ? error : helpText}
    {...input}
    {...rest}
  />
);

export const FormDateField = ({
  label,
  id,
  input,
  helpText,
  meta: {touched, invalid, error},
  ...rest
}) => (
  <CustomDatePicker
    id={id}
    dateValue={input.value}
    onDateChange={input.onChange}
    error={touched && invalid}
    helpText={touched && error ? error : helpText}
    {...input}
    {...rest}
  />
);

export const FormTimeField = ({
  label,
  input,
  helpText,
  meta: {touched, invalid, error},
  ...rest
}) => (
  <CustomTimePicker
    timeValue={input.value}
    onTimeChange={input.onChange}
    error={touched && invalid}
    helpText={touched && error ? touched && error : helpText}
    {...input}
    {...rest}
  />
);

export const FormSelectField = ({
  children,
  selectedValue,
  handleSelect,
  input,
  label,
  htmlFor,
  required,
  helpText,
  options,
  meta: {touched, error},
  ...rest
}) => (
  <CustomSimpleSelect
    htmlFor={htmlFor}
    label={label}
    selectedValue={input.value}
    handleSelect={input.onChange}
    options={options}
    error={touched}
    helpText={touched && error ? error : helpText}
    required={required}
    {...input}
    {...rest}
  />
);

export const FormTextField = compose(
  withStyles(formFieldStyle),
  connect()
)(
  ({
    classes,
    label,
    input,
    required,
    id,
    type,
    helpText,
    additionalInputProps,
    meta: {touched, invalid, error},
    writeOnce,
    clearButton,
    dispatch,
    formName,
    reduxName,
    ...rest
  }) => {
    return (
      <MuiThemeProvider theme={textFieldTheme}>
        <div style={{position: 'relative'}}>
          <TextField
            id={id}
            type={type}
            label={label}
            fullWidth
            required={required}
            inputProps={{value: input.value, onChange: input.onChange}}
            InputProps={additionalInputProps}
            error={touched && invalid}
            disabled={writeOnce}
            helperText={touched && error ? error : helpText}
            {...input}
            {...rest}
          />
          {clearButton === true && (
            <ClearButton
              formName={formName}
              reduxName={reduxName}
              dispatch={dispatch}
              clearButtonClasses={classes.textClearButton}
            />
          )}
        </div>
      </MuiThemeProvider>
    );
  }
);

export const FormMultipleSelectField = ({
  children,
  label,
  handleSelect,
  input,
  type,
  typeHeight,
  htmlFor,
  helpText,
  options,
  required,
  meta: {touched, error},
  ...rest
}) => {
  return (
    <CustomChipMultipleSelect
      htmlFor={htmlFor}
      label={label}
      selectedValues={input.value ? input.value : []}
      handleSelect={input.onChange}
      type={type}
      typeHeight={typeHeight}
      options={options}
      error={touched}
      helpText={touched && error ? error : helpText}
      required={required}
      {...input}
      {...rest}
    />
  );
};

export const FormCustomSelectField = ({
  children,
  selectedValue,
  handleSelect,
  input,
  label,
  htmlFor,
  required,
  helpText,
  options,
  meta: {touched, error},
  ...rest
}) => (
  <CustomSelect
    htmlFor={htmlFor}
    label={label}
    selectedValue={input.value}
    handleSelect={input.onChange}
    options={options}
    error={touched}
    helpText={touched && error ? error : helpText}
    required={required}
    {...input}
    {...rest}
  />
);

const adaptFileEventToValue = (delegate) => (e) => delegate(e.target.files[0]);

export const FileInput = ({
  input: {value: omitValue, onChange, onBlur, ...inputProps},
  fileLabel,
  meta: omitMeta,
  ...props
}) => {
  return (
    <div>
      <label htmlFor="file">{fileLabel}</label>
      <input
        onChange={adaptFileEventToValue(onChange)}
        onBlur={adaptFileEventToValue(onBlur)}
        type="file"
        {...props.input}
        {...props}
      />
    </div>
  );
};
