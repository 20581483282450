/*!

 =========================================================
 * Material Dashboard PRO React - v1.5.0 based on Material Dashboard PRO - v1.2.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2018 Creative Tim (https://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

// ##############################
// // // Function that converts from hex color to rgb color
// // // Example: input = #9c27b0 => output = 156, 39, 176
// // // Example: input = 9c27b0 => output = 156, 39, 176
// // // Example: input = #999 => output = 153, 153, 153
// // // Example: input = 999 => output = 153, 153, 153
// #############################
const hexToRgb = (input) => {
  input = input + '';
  input = input.replace('#', '');
  let hexRegex = /[0-9A-Fa-f]/g;
  if (!hexRegex.test(input) || (input.length !== 3 && input.length !== 6)) {
    throw new Error('input is not a valid hex color.');
  }
  if (input.length === 3) {
    let first1 = input[0];
    let second1 = input[1];
    let last1 = input[2];
    input = first1 + first1 + second1 + second1 + last1 + last1;
  }
  input = input.toUpperCase(input);
  let first = input[0] + input[1];
  let second = input[2] + input[3];
  let last = input[4] + input[5];
  return parseInt(first, 16) + ', ' + parseInt(second, 16) + ', ' + parseInt(last, 16);
};

// ##############################
// // // Variables - Styles that are used on more than one component
// #############################

const drawerWidth = 260;

const drawerMiniWidth = 80;

const transition = {
  transition: 'all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)',
};

const containerFluid = {
  paddingRight: '15px',
  paddingLeft: '15px',
  marginRight: 'auto',
  marginLeft: 'auto',
  '&:before,&:after': {
    display: 'table',
    content: '" "',
  },
  '&:after': {
    clear: 'both',
  },
};

const container = {
  paddingRight: '15px',
  paddingLeft: '15px',
  marginRight: 'auto',
  marginLeft: 'auto',
  '@media (min-width: 768px)': {
    width: '750px',
  },
  '@media (min-width: 992px)': {
    width: '970px',
  },
  '@media (min-width: 1200px)': {
    width: '1170px',
  },
  '&:before,&:after': {
    display: 'table',
    content: '" "',
  },
  '&:after': {
    clear: 'both',
  },
};

const defaultFont = {
  fontFamily: 'Campton, Arial, sans-serif',
  fontWeight: '300',
  lineHeight: '1.5em',
};

// new lkq colors
const primaryColor = '#085CF0';
const blueColor10 = '#C0DDFF';
const blueColor20 = '#5F9AFF';
const blueColor50 = '#0043B8';
const blueColor80 = '#002C7B';
const blueColor90 = '#040E32';

const grayColor10 = '#FAFAFA';
const grayColor20 = '#F2F2F2';
const grayColor50 = '#D2D2D2';
const grayColor60 = '#999999';
const grayColor80 = '#2E2E2E';
const grayColor90 = '#1A1A1A';

const purpleColor = '#9c27b0';

const warningColor = ['#ff9800', '#ffa726', '#fb8c00', '#ffa21a', '#f57c00', '#faf2cc', '#fcf8e3'];
const dangerColor = ['#f44336', '#ef5350', '#e53935', '#f55a4e', '#d32f2f', '#ebcccc', '#f2dede'];
const successColor = ['#4caf50', '#66bb6a', '#43a047', '#5cb860', '#388e3c', '#d0e9c6', '#dff0d8'];
const infoColor = ['#00acc1', '#26c6da', '#00acc1', '#00d3ee', '#0097a7', '#c4e3f3', '#d9edf7'];
const roseColor = ['#e91e63', '#ec407a', '#d81b60', '#eb3573', '#c2185b'];

const blackColor = '#000000';
const whiteColor = '#ffffff';
const twitterColor = '#55acee';
const facebookColor = '#3b5998';
const googleColor = '#dd4b39';
const linkedinColor = '#0976b4';
const pinterestColor = '#cc2127';
const youtubeColor = '#e52d27';
const tumblrColor = '#35465c';
const behanceColor = '#1769ff';
const dribbbleColor = '#ea4c89';
const redditColor = '#ff4500';

const blueButtonShadow = {
  backgroundColor: blueColor80,
  color: whiteColor,
  boxShadow:
    '0 2px 2px 0 rgba(' +
    hexToRgb(blueColor80) +
    ', 0.14), 0 3px 1px -2px rgba(' +
    hexToRgb(blueColor80) +
    ', 0.2), 0 1px 5px 0 rgba(' +
    hexToRgb(blueColor80) +
    ', 0.12)',
  '&:hover,&:focus': {
    backgroundColor: blueColor80,
    color: whiteColor,
    boxShadow:
      '0 14px 26px -12px rgba(' +
      hexToRgb(blueColor80) +
      ', 0.42), 0 4px 23px 0px rgba(' +
      hexToRgb(blackColor) +
      ', 0.12), 0 8px 10px -5px rgba(' +
      hexToRgb(blueColor80) +
      ', 0.2)',
  },
};

// general gray button
const grayButton = {
  minHeight: 'auto',
  minWidth: 'auto',
  backgroundColor: grayColor60,
  color: whiteColor,
  boxShadow:
    '0 2px 2px 0 rgba(' +
    hexToRgb(grayColor60) +
    ', 0.14), 0 3px 1px -2px rgba(' +
    hexToRgb(grayColor60) +
    ', 0.2), 0 1px 5px 0 rgba(' +
    hexToRgb(grayColor60) +
    ', 0.12)',
  border: 'none',
  borderRadius: '3px',
  position: 'relative',
  padding: '12px 30px',
  margin: '.3125rem 1px',
  fontSize: '12px',
  fontWeight: '400',
  textTransform: 'uppercase',
  letterSpacing: '0',
  willChange: 'box-shadow, transform',
  transition:
    'box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
  lineHeight: '1.42857143',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  verticalAlign: 'middle',
  touchAction: 'manipulation',
  cursor: 'pointer',
  '&:hover,&:focus': {
    color: whiteColor,
    backgroundColor: grayColor60,
    boxShadow:
      '0 14px 26px -12px rgba(' +
      hexToRgb(grayColor60) +
      ', 0.42), 0 4px 23px 0px rgba(' +
      hexToRgb(blackColor) +
      ', 0.12), 0 8px 10px -5px rgba(' +
      hexToRgb(grayColor60) +
      ', 0.2)',
  },
};

const boxShadow = {
  boxShadow:
    '0 10px 30px -12px rgba(' +
    hexToRgb(blackColor) +
    ', 0.42), 0 4px 25px 0px rgba(' +
    hexToRgb(blackColor) +
    ', 0.12), 0 8px 10px -5px rgba(' +
    hexToRgb(blackColor) +
    ', 0.2)',
};

const primaryBoxShadow = {
  boxShadow:
    '0 4px 20px 0 rgba(' +
    hexToRgb(blackColor) +
    ',.14), 0 7px 10px -5px rgba(' +
    hexToRgb(primaryColor) +
    ',.4)',
};
const infoBoxShadow = {
  boxShadow:
    '0 4px 20px 0 rgba(' +
    hexToRgb(blackColor) +
    ',.14), 0 7px 10px -5px rgba(' +
    hexToRgb(infoColor[0]) +
    ',.4)',
};
const successBoxShadow = {
  boxShadow:
    '0 4px 20px 0 rgba(' +
    hexToRgb(blackColor) +
    ',.14), 0 7px 10px -5px rgba(' +
    hexToRgb(successColor[0]) +
    ',.4)',
};
const warningBoxShadow = {
  boxShadow:
    '0 4px 20px 0 rgba(' +
    hexToRgb(blackColor) +
    ',.14), 0 7px 10px -5px rgba(' +
    hexToRgb(warningColor[0]) +
    ',.4)',
};
const dangerBoxShadow = {
  boxShadow:
    '0 4px 20px 0 rgba(' +
    hexToRgb(blackColor) +
    ',.14), 0 7px 10px -5px rgba(' +
    hexToRgb(dangerColor[0]) +
    ',.4)',
};
const roseBoxShadow = {
  boxShadow:
    '0 4px 20px 0 rgba(' +
    hexToRgb(blackColor) +
    ',.14), 0 7px 10px -5px rgba(' +
    hexToRgb(roseColor[0]) +
    ',.4)',
};

const blueBoxShadow = {
  boxShadow:
    '0 4px 20px 0 rgba(' +
    hexToRgb(blackColor) +
    ',.14), 0 7px 10px -5px rgba(' +
    hexToRgb(blueColor80) +
    ',.4)',
};

const warningCardHeader = {
  background: 'linear-gradient(60deg, ' + warningColor[1] + ', ' + warningColor[2] + ')',
  ...warningBoxShadow,
};
const successCardHeader = {
  background: 'linear-gradient(60deg, ' + successColor[1] + ', ' + successColor[2] + ')',
  ...successBoxShadow,
};
const dangerCardHeader = {
  background: 'linear-gradient(60deg, ' + dangerColor[1] + ', ' + dangerColor[2] + ')',
  ...dangerBoxShadow,
};
const infoCardHeader = {
  background: 'linear-gradient(60deg, ' + infoColor[1] + ', ' + infoColor[2] + ')',
  ...infoBoxShadow,
};
const primaryCardHeader = {
  backgroundColor: blueColor80,
  ...primaryBoxShadow,
};

const roseCardHeader = {
  background: 'linear-gradient(60deg, ' + roseColor[1] + ', ' + roseColor[2] + ')',
  ...roseBoxShadow,
};
const blueCardHeader = {
  backgroundColor: blueColor80 + ' !important',
  ...blueBoxShadow,
};

const defaultBoxShadow = {
  border: '0',
  borderRadius: '3px',
  boxShadow:
    '0 10px 20px -12px rgba(' +
    hexToRgb(blackColor) +
    ', 0.42), 0 3px 20px 0px rgba(' +
    hexToRgb(blackColor) +
    ', 0.12), 0 8px 10px -5px rgba(' +
    hexToRgb(blackColor) +
    ', 0.2)',
  padding: '10px 0',
  transition: 'all 150ms ease 0s',
};

const tooltip = {
  padding: '10px 15px',
  minWidth: '130px',
  color: whiteColor,
  lineHeight: '1.7em',
  background: 'rgba(' + hexToRgb(grayColor80) + ',0.9)',
  border: 'none',
  borderRadius: '3px',
  opacity: '1!important',
  boxShadow:
    '0 8px 10px 1px rgba(' +
    hexToRgb(blackColor) +
    ', 0.14), 0 3px 14px 2px rgba(' +
    hexToRgb(blackColor) +
    ', 0.12), 0 5px 5px -3px rgba(' +
    hexToRgb(blackColor) +
    ', 0.2)',
  maxWidth: '200px',
  textAlign: 'center',
  fontFamily: 'Campton, Arial, sans-serif',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '400',
  textShadow: 'none',
  textTransform: 'none',
  letterSpacing: 'normal',
  wordBreak: 'normal',
  wordSpacing: 'normal',
  wordWrap: 'normal',
  whiteSpace: 'normal',
  lineBreak: 'auto',
};

const title = {
  color: grayColor80,
  textDecoration: 'none',
  fontWeight: '300',
  marginTop: '30px',
  marginBottom: '25px',
  minHeight: '32px',
  fontFamily: "'SharpGrotesk', 'Arial', sans-serif",
  '& small': {
    color: grayColor80,
    fontSize: '65%',
    fontWeight: '400',
    lineHeight: '1',
  },
};

const cardTitle = {
  ...title,
  marginTop: '0',
  marginBottom: '3px',
  minHeight: 'auto',
  '& a': {
    ...title,
    marginTop: '.625rem',
    marginBottom: '0.75rem',
    minHeight: 'auto',
  },
};

export {
  hexToRgb,
  //variables
  drawerWidth,
  drawerMiniWidth,
  transition,
  container,
  containerFluid,
  boxShadow,
  defaultFont,
  primaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  blackColor,
  whiteColor,
  twitterColor,
  facebookColor,
  googleColor,
  linkedinColor,
  pinterestColor,
  youtubeColor,
  tumblrColor,
  behanceColor,
  dribbbleColor,
  redditColor,
  primaryBoxShadow,
  infoBoxShadow,
  successBoxShadow,
  warningBoxShadow,
  dangerBoxShadow,
  roseBoxShadow,
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
  blueCardHeader,
  defaultBoxShadow,
  tooltip,
  title,
  cardTitle,
  blueColor10,
  blueColor20,
  blueColor50,
  blueColor80,
  blueColor90,
  grayColor10,
  grayColor20,
  grayColor50,
  grayColor60,
  grayColor80,
  grayColor90,
  purpleColor,
  blueButtonShadow,
  grayButton,
};
