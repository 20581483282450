import {API_PREFIX, EC24_API_PREFIX} from '_config/configConstants';

export const API_ENDPOINT_MAP = {
  login: `${API_PREFIX}/auth/login/`,
  logout: `${API_PREFIX}/auth/logout/`,
  carts: `${API_PREFIX}/carts/`,
  customers: `${API_PREFIX}/customers/`,
  users: `${API_PREFIX}/users/`,
  me: `${API_PREFIX}/my-user/`,
  impersonate: `${API_PREFIX}/impersonate/`,
  branches: `${API_PREFIX}/branches/`,
  deliveryOptions: `${API_PREFIX}/delivery-options/`,
  permissions: `${API_PREFIX}/permissions/`,
  customerPermissionMembership: `${API_PREFIX}/customer-permission-memberships/`,
  customerAccessiblePermissions: `${API_PREFIX}/accessible-permission-memberships/`,
  deliveryOptionMemberships: `${API_PREFIX}/delivery-option-memberships/`,
  userPermissionMembership: `${API_PREFIX}/user-permission-memberships/`,
  groups: `${API_PREFIX}/groups/`,
  lostSaleReason: `${API_PREFIX}/lost-sale-reasons/`,
  lastLogin: `${API_PREFIX}/last-login/`,
  myCustomer: `${API_PREFIX}/my-customer/`,
  logo: `${API_PREFIX}/my-customer/upload-logo/`,
  discountCategories: `${API_PREFIX}/discount-categories/`,
  discountGroups: `${API_PREFIX}/discount-groups/`,
  discountLimits: `${API_PREFIX}/discount-limits/`,
  customersDiscountGroupMembership: `${API_PREFIX}/discount-group-memberships/`,
  notifications: `${API_PREFIX}/notifications/`,
  myDeliveryOptions: `${API_PREFIX}/my-delivery-options/`,
  management: `${API_PREFIX}/management/`,
  wholesalerAvailability: `${API_PREFIX}/wholesaler-availability/`,
  activation: `${API_PREFIX}/activation`,
  activationResetPassword: `${API_PREFIX}/activation/reset-password`,
  stammnummer: `${API_PREFIX}/stammnummer-ktype-link`,
  typenscheinnummer: `${API_PREFIX}/typenscheinnummer-ktype-link`,

  forgottenPassword: `${EC24_API_PREFIX}/reset-password`, // endpoint is for reset password, functionality though is for forgotten password

  config: `${API_PREFIX}/config/current/`,
};
