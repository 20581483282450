import {grayColor20} from '_assets/global/jss/styleHelpers';

export const discountGroupsTabStyle = (theme) => ({
  tableWrapper: {
    overflow: 'overlay',
    paddingBottom: '15px',
  },
  filterCard: {
    background: grayColor20,
    margin: '20px 0 50px 0',
  },
  CircularProgress: {
    textAlign: 'center',
  },
  importProgressBar: {
    width: '100%',
  },
  importProgressText: {
    paddingBottom: '15px',
  },
});
