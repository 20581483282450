import {filterCustomerFormStyle} from '_assets/projectSpecific/jss/components/Forms/filterCustomerFormStyle';
import Button from '_components/global/Buttons/Button';
import {FormSelectField, FormTextField} from '_components/global/Form/FormFields';
import GridContainer from '_components/global/Grid/GridContainer';
import GridItem from '_components/global/Grid/GridItem';
import {VEHICLE_TYPES} from '_config/constants';
import React from 'react';
import compose from 'recompose/compose';
import {Field, reduxForm} from 'redux-form';
import {Trans} from '@lingui/macro';
import Tooltip from '@material-ui/core/Tooltip';
import {withStyles} from '@material-ui/core/styles';
import {Replay, Search} from '@material-ui/icons';

const FilterVRForm = ({form, classes, handleSubmit, reset}) => {
  return (
    <form onSubmit={handleSubmit} className={classes.flex}>
      <GridContainer className={classes.cartFormFields}>
        <GridItem xs={12} md={3}>
          <Field
            name="number"
            label={<Trans>Number</Trans>}
            id="number"
            component={FormTextField}
            clearButton={true}
            reduxName="number"
            formName={form}
          />
        </GridItem>

        <GridItem xs={12} md={3}>
          <Field
            name="vehicle_type"
            id="vehicle_type"
            type={'select'}
            label={<Trans>Type of vehicle</Trans>}
            component={FormSelectField}
            options={VEHICLE_TYPES}
            reduxName="vehicle_type"
            formName={form}
            clearButton={true}
          />
        </GridItem>

        <GridItem xs={12} md={6}>
          <div className={classes.cartButtons}>
            <Tooltip title={<Trans>Find</Trans>}>
              <Button color="primary" type="submit">
                <Search />
              </Button>
            </Tooltip>
            <Tooltip title={<Trans>Reset filter</Trans>}>
              <Button
                onClick={() => {
                  reset();
                }}
                color="primary"
              >
                <Replay />
              </Button>
            </Tooltip>
          </div>
        </GridItem>
      </GridContainer>
    </form>
  );
};

export default compose(
  withStyles(filterCustomerFormStyle),
  reduxForm({
    enableReinitialize: true,
  })
)(FilterVRForm);
