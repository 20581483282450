import {grayColor50, hexToRgb} from '_assets/global/jss/styleHelpers';
import {createMuiTheme} from '@material-ui/core/styles/index';

export const removableTagStyle = (theme) => ({
  customGroupTag: {
    backgroundColor: 'rgba(' + hexToRgb(grayColor50) + ', 0.8)',
    borderRadius: '4px',
    padding: '4px 8px',
    lineHeight: '1rem',
    fontSize: '0.7rem',
    display: 'inline-flex',
    marginRight: '5px',
    marginBottom: '5px',
    [theme.breakpoints.down('lg')]: {
      marginBottom: '2px',
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
  customGroup: {
    whiteSpace: 'nowrap',
  },
  customGroupTagRemovable: {
    whiteSpace: 'nowrap',
    marginRight: '10px',
  },
});

export const closeIconTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiSvgIcon: {
      root: {
        fontSize: '0.8rem',
      },
    },
    MuiIconButton: {
      root: {
        padding: 0,
      },
    },
  },
});
