import React from 'react';
import Tabs from '@material-ui/core/Tabs';

const CommonTabs = ({value, onChange, children}) => {
  return (
    <Tabs
      value={value}
      onChange={onChange}
      indicatorColor="primary"
      textColor="primary"
      variant="fullWidth"
    >
      {children}
    </Tabs>
  );
};

export default CommonTabs;
