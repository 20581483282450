import {
  blackColor,
  cardTitle,
  dangerColor,
  grayColor60,
  hexToRgb,
  successColor,
  whiteColor,
} from '_assets/global/jss/styleHelpers';

export const ordersPageStyle = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400',
    },
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflow: 'overlay',
    paddingBottom: '15px',
  },
  actions: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  unseenRow: {
    background: '#ff505038',
  },
  cell: {
    padding: '0px 10px',
    whiteSpace: 'nowrap',
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  allowSendAllOrdersColor: {
    backgroundColor: successColor[0],
    '&:hover, &:focus': {
      backgroundColor: successColor[1],
    },
  },
  notAllowSendAllOrdersColor: {
    backgroundColor: dangerColor[0],
    '&:hover, &:focus': {
      backgroundColor: dangerColor[1],
    },
  },
  successTextColor: {
    color: successColor[0],
    fontWeight: 500,
  },
  dangerTextColor: {
    color: dangerColor[1],
    fontWeight: 500,
  },
  popperResponsive: {
    zIndex: '1200',
    [theme.breakpoints.down('sm')]: {
      zIndex: '1060',
      position: 'static',
      float: 'none',
      width: 'auto',
      marginTop: '0',
      backgroundColor: 'transparent',
      border: '0',
      boxShadow: 'none',
      color: 'black',
    },
  },
  dropDown: {
    borderRadius: '3px',
    border: '0',
    boxShadow: '0 2px 5px 0 rgba(' + hexToRgb(blackColor) + ', 0.26)',
    top: '100%',
    zIndex: '1000',
    minWidth: '160px',
    padding: '20px',
    margin: '2px 0 0',
    fontSize: '14px',
    textAlign: 'left',
    listStyle: 'none',
    backgroundColor: whiteColor,
    backgroundClip: 'padding-box',
  },
  headerDropDown: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'gray',
    paddingLeft: '10px',
    borderRadius: '3px',
    color: '#fff',
    fontWeight: 'bold',
    marginBottom: '15px',
  },
  buttonPosition: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '15px',
  },
  hide: {
    display: 'none',
  },
  countdownTimer: {
    borderRadius: '3px',
    backgroundColor: grayColor60,
    color: whiteColor,
    padding: '15px',
    fontWeight: 'bold',
    marginTop: '10px',
  },
});
