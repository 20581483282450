import {
  blackColor,
  blueButtonShadow,
  grayColor50,
  grayColor60,
  hexToRgb,
  primaryColor,
  whiteColor,
} from '_assets/global/jss/styleHelpers';
import {createMuiTheme} from '@material-ui/core';

export const permissionsFormStyle = (theme) => ({
  button: {
    minHeight: 'auto',
    height: '40px',
    minWidth: 'auto',
    backgroundColor: grayColor60,
    color: whiteColor,
    boxShadow:
      '0 2px 2px 0 rgba(' +
      hexToRgb(grayColor60) +
      ', 0.14), 0 3px 1px -2px rgba(' +
      hexToRgb(grayColor60) +
      ', 0.2), 0 1px 5px 0 rgba(' +
      hexToRgb(grayColor60) +
      ', 0.12)',
    border: 'none',
    borderRadius: '3px',
    position: 'relative',
    padding: '12px 30px',
    margin: '.3125rem 1px',
    fontSize: '12px',
    fontWeight: '400',
    textTransform: 'uppercase',
    letterSpacing: '0',
    willChange: 'box-shadow, transform',
    transition:
      'box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
    lineHeight: '1.42857143',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    '&:hover,&:focus': {
      color: whiteColor,
      backgroundColor: grayColor60,
      boxShadow:
        '0 14px 26px -12px rgba(' +
        hexToRgb(grayColor60) +
        ', 0.42), 0 4px 23px 0px rgba(' +
        hexToRgb(blackColor) +
        ', 0.12), 0 8px 10px -5px rgba(' +
        hexToRgb(grayColor60) +
        ', 0.2)',
    },
  },
  primary: {
    backgroundColor: primaryColor,
    boxShadow:
      '0 2px 2px 0 rgba(' +
      hexToRgb(primaryColor) +
      ', 0.14), 0 3px 1px -2px rgba(' +
      hexToRgb(primaryColor) +
      ', 0.2), 0 1px 5px 0 rgba(' +
      hexToRgb(primaryColor) +
      ', 0.12)',
    '&:hover,&:focus': {
      backgroundColor: primaryColor,
      boxShadow:
        '0 14px 26px -12px rgba(' +
        hexToRgb(primaryColor) +
        ', 0.42), 0 4px 23px 0px rgba(' +
        hexToRgb(blackColor) +
        ', 0.12), 0 8px 10px -5px rgba(' +
        hexToRgb(primaryColor) +
        ', 0.2)',
    },
  },
  blue: blueButtonShadow,
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '25px',
    alignItems: 'center',
  },
  select: {
    width: '70%',
  },
  formActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '20px',
  },
  margin: {
    marginTop: '20px',
  },
  cell: {
    padding: '0px 5px',
  },
});

export const tableHeadTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiTableHead: {
      root: {
        backgroundColor: `${grayColor50} !important`,
      },
    },
  },
});
