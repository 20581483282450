import {blueButtonShadow, grayButton} from '_assets/global/jss/styleHelpers';
import {createMuiTheme} from '@material-ui/core';

export const companySettingsFormStyle = (theme) => ({
  button: grayButton,
  blue: blueButtonShadow,
  formActions: {
    borderTop: '3px solid #fff',
    marginTop: '45px',
    padding: theme.spacing.unit * 3,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  margin: {
    margin: '20px 20px',
    padding: '20px 0',
  },
});

export const selectPrefixFieldTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiFormControl: {
      root: {
        minWidth: '90% !important',
      },
    },
  },
});
