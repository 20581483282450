import {
  blackColor,
  blueButtonShadow,
  grayButton,
  grayColor60,
  hexToRgb,
  primaryColor,
} from '_assets/global/jss/styleHelpers';

export const vehicleReferencesFormStyle = (theme) => ({
  form: {
    position: 'relative',
    padding: '20px',
  },
  button: grayButton,
  primary: {
    backgroundColor: primaryColor,
    boxShadow:
      '0 2px 2px 0 rgba(' +
      hexToRgb(primaryColor) +
      ', 0.14), 0 3px 1px -2px rgba(' +
      hexToRgb(primaryColor) +
      ', 0.2), 0 1px 5px 0 rgba(' +
      hexToRgb(primaryColor) +
      ', 0.12)',
    '&:hover,&:focus': {
      backgroundColor: primaryColor,
      boxShadow:
        '0 14px 26px -12px rgba(' +
        hexToRgb(primaryColor) +
        ', 0.42), 0 4px 23px 0px rgba(' +
        hexToRgb(blackColor) +
        ', 0.12), 0 8px 10px -5px rgba(' +
        hexToRgb(primaryColor) +
        ', 0.2)',
    },
  },
  blue: blueButtonShadow,
  formActions: {
    borderTop: '3px solid #fff',
    marginTop: '45px',
    padding: theme.spacing.unit * 3,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  addButton: {
    width: '100%',
    border: '2px dashed ' + grayColor60,
    opacity: 0.75,
    color: grayColor60,
    textAlign: 'center',
    cursor: 'pointer',
    padding: 10,
    margin: '15px 0',
    fontWeight: 700,
    borderRadius: 3,
    transition: 'opacity 200ms',
    fontFamily: 'Campton',
    fontSize: '14px',

    '&:hover': {
      transition: 'opacity 200ms',
      opacity: 1,
    },

    '&[disabled]': {
      cursor: 'not-allowed',
    },
    '&:disabled': {
      cursor: 'not-allowed',
    },
  },
  referenceTableHead: {
    marginTop: '16px',
    paddingBottom: '4px',
    borderBottom: '1px solid black',
  },
});
