import {blueButtonShadow, grayButton} from '_assets/global/jss/styleHelpers';

export const globalPermissionsFormStyle = (theme) => ({
  button: grayButton,
  blue: blueButtonShadow,
  formActions: {
    padding: theme.spacing.unit * 3,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  permissionForm: {
    paddingTop: '20px',
  },
});
