import {grayColor20} from '_assets/global/jss/styleHelpers';

export const customersInDiscountGroupsStyle = (theme) => ({
  cell: {
    padding: '0px 5px',
    whiteSpace: 'nowrap',
    '&:first-of-type': {
      padding: '0px 5px 0px 10px',
    },
    '&:last-of-type': {
      padding: '0px 10px 0px 5px',
    },
  },
  filterCard: {
    background: grayColor20,
    margin: '20px 0 50px 0',
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflow: 'overlay',
    paddingBottom: '15px',
  },
});
