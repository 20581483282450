import {accountFormStyle} from '_assets/projectSpecific/jss/components/Forms/accountFormStyle';
import Button from '_components/global/Buttons/Button';
import {
  FormCheckbox,
  FormSelectField,
  FormSwitcher,
  FormTextField,
} from '_components/global/Form/FormFields';
import GridContainer from '_components/global/Grid/GridContainer';
import GridItem from '_components/global/Grid/GridItem';
import {LANGUAGES} from '_config/configConstants';
import {Can} from '_helpers/global/AuthorizedAbilityContext';
import {
  email,
  maxLength256,
  required,
  validatePasswordPolicies,
} from '_helpers/global/FieldLevelValidation';
import cx from 'classnames';
import React from 'react';
import compose from 'recompose/compose';
import {Field, reduxForm} from 'redux-form';
import {Trans} from '@lingui/macro';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import {withStyles} from '@material-ui/core/styles';
import {Person, Visibility, VisibilityOff} from '@material-ui/icons';

const validateRepeatingPassword = (value, values) =>
  value !== values.newPassword && <Trans>Passwords do not match!</Trans>;

const GlobalSettingsForm = ({
  handleSubmit,
  pristine,
  reset,
  submitting,
  submitName,
  form,
  classes,
  authType,
}) => {
  const [values, setValues] = React.useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({...values, showPassword: !values.showPassword});
  };

  return (
    <form onSubmit={handleSubmit} className={classes.userForm}>
      <GridContainer justify="center">
        <Can I="changeAuthType" a="MyUser">
          <GridItem xs={12} md={9} style={{marginBottom: '15px'}}>
            <Field
              name="auth_type"
              component={FormSwitcher}
              helpText={<Trans>Help text for setting auth type</Trans>}
              valueA="Local type"
              valueB="Oracle type"
            />
          </GridItem>
        </Can>
        <GridItem xs={12} md={9}>
          <Field
            name="username"
            label={<Trans>Login name</Trans>}
            id="username"
            disabled={form !== 'createAccount'}
            additionalInputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton disabled aria-label="username">
                    <Person />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            component={FormTextField}
            validate={form === 'createAccount' ? required : []}
            required={form === 'createAccount'}
          />
        </GridItem>
        <GridItem xs={12} md={9}>
          <Field
            name="name"
            label={<Trans>Name</Trans>}
            id="name"
            disabled={form !== 'createAccount'}
            component={FormTextField}
            validate={maxLength256}
          />
        </GridItem>
        {!authType ? (
          <>
            <GridItem xs={12} md={9}>
              <Field
                name="current_password"
                label={<Trans>Current password</Trans>}
                type={values.showPassword ? 'text' : 'password'}
                additionalInputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                component={FormTextField}
              />
            </GridItem>
            <GridItem xs={12} md={9}>
              <Field
                name="newPassword"
                label={<Trans>New password</Trans>}
                type={values.showPassword ? 'text' : 'password'}
                additionalInputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                validate={validatePasswordPolicies}
                component={FormTextField}
              />
            </GridItem>
            <GridItem xs={12} md={9}>
              <Field
                name="repeat_password"
                label={<Trans>Repeat new password</Trans>}
                type={values.showPassword ? 'text' : 'password'}
                additionalInputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                component={FormTextField}
                validate={validateRepeatingPassword}
              />
            </GridItem>
          </>
        ) : (
          ''
        )}
        <GridItem xs={12} md={9}>
          <Field
            name="email"
            label={<Trans>Email</Trans>}
            id="email"
            type="email"
            component={FormTextField}
            validate={[email, maxLength256]}
          />
        </GridItem>
        <GridItem xs={12} md={9}>
          <Field
            name="language"
            htmlFor="language"
            label={<Trans>Default language</Trans>}
            id="language"
            type="select"
            component={FormSelectField}
            options={LANGUAGES}
          />
        </GridItem>
        <GridItem xs={12} md={9}>
          <Field
            name="enable_notification"
            component={FormCheckbox}
            label={<Trans>Send Notifications</Trans>}
          />
        </GridItem>
        <Can I="update" a="MyUser">
          <GridItem xs={12}>
            <div className={classes.formActions}>
              <Button
                onClick={reset}
                className={cx(classes.button)}
                disabled={pristine || submitting}
              >
                <Trans>Reset</Trans>
              </Button>
              <Button color={'primary'} disabled={pristine || submitting} type="submit">
                {submitName}
              </Button>
            </div>
          </GridItem>
        </Can>
      </GridContainer>
    </form>
  );
};

export default compose(
  withStyles(accountFormStyle),
  reduxForm({
    enableReinitialize: true,
  })
)(GlobalSettingsForm);
