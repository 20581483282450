import {updateGlobalSettings} from '_actions/global/globalSettingsActions';
import {updateLoader, updateLocalLoader} from '_actions/global/loaderActions';
import {
  confirmImportDiscountGroup,
  exportDiscountGroup,
  getDiscountCategories,
  importDiscountGroup,
  saveDiscountCategoriesChanges,
} from '_actions/projectSpecific/discountCategoriesActions';
import {
  addDiscountGroup,
  deleteDiscountGroup,
  getDiscountGroups,
} from '_actions/projectSpecific/discountGroupsActions';
import {discountGroupsTabStyle} from '_assets/projectSpecific/jss/components/Tabs/discountGroupsTabStyle';
import Card from '_components/global/Card/Card';
import CardBody from '_components/global/Card/CardBody';
import Dialog from '_components/global/Dialogs/Dialog';
import GridContainer from '_components/global/Grid/GridContainer';
import GridItem from '_components/global/Grid/GridItem';
import WarningAlert from '_components/global/SweetAlerts/WarningSweetAlert';
import CustomTablePagination from '_components/global/Tables/CustomTablePagination';
import RemovableTag from '_components/global/Tags/RemovableTag';
import DiscountGroupsListForm from '_components/projectSpecific/Forms/DiscountGroupsListForm';
import FilterDiscountCategoriesForm from '_components/projectSpecific/Forms/FilterDiscountCategoriesForm';
import ImportDiscountGroupsList from '_components/projectSpecific/Forms/ImportDiscountGroupsList';
import DiscountGroupsTableToolbar from '_components/projectSpecific/Toolbars/DiscountGroupsTableToolbar';
import {
  fireErrorToast,
  fireSuccessToast,
  getComputedOrderBy,
  prepareSortingState,
} from '_helpers/global/functions';
import download from 'downloadjs';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {bindActionCreators} from 'redux';
import {getFormValues} from 'redux-form';
import {Trans} from '@lingui/macro';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import {withStyles} from '@material-ui/core/styles';

const defaultRows = [
  {
    id: 'id',
    label: <Trans>ID</Trans>,
    sortable: true,
  },
  {
    id: 'action',
    label: <Trans>Actions</Trans>,
    sortable: false,
    permissions: {
      action: 'update',
      subject: 'DiscountGroup',
    },
  },
  {
    id: 'category_id',
    label: <Trans>Category ID</Trans>,
    sortable: true,
  },
  {
    id: 'category_description',
    label: <Trans>Category description</Trans>,
    sortable: false,
  },
];

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateLoader,
      updateGlobalSettings,
      getDiscountCategories,
      saveDiscountCategoriesChanges,
      addDiscountGroup,
      deleteDiscountGroup,
      getDiscountGroups,
      importDiscountGroup,
      exportDiscountGroup,
      updateLocalLoader,
      confirmImportDiscountGroup,
    },
    dispatch
  );
};

class DiscountGroupsTab extends Component {
  toastId = null;
  intervalID = null;

  constructor(props) {
    super(props);
    this.state = {
      order: 'asc',
      page: 0,
      orderBy: this.props.DiscountCategoriesListFilter.orderBy,
      categoryId: this.props.DiscountCategoriesListFilter.categoryId,
      inputLocked: true,
      openDeleteDiscountGroups: false,
      openConfirmImport: false,
      importInProgress: false,
      importErrors: [],
      openErrorImport: false,
      activeLineEdit: null,
      rows: [...defaultRows],
      dialogRows: [
        ...defaultRows.filter((row) => row.id !== 'id').filter((row) => row.id !== 'action'),
      ],
    };
  }

  getDynamicRows = (discountCategories = this.props.discountCategories[0]) => {
    if (discountCategories) {
      return discountCategories.discount_groups.map((group) => {
        return {id: `${group.id}`, label: `${group.name}`, sortable: false};
      });
    }
    return [];
  };

  componentDidMount = () => {
    this.fetchDiscountCategories();
  };

  fetchDiscountCategories = () => {
    this.props.updateLoader({globalLoading: true});
    return this.props
      .getDiscountCategories(
        this.state.page * this.props.globalSettings.itemsPerPage,
        this.props.globalSettings.itemsPerPage,
        getComputedOrderBy(this.state.orderBy, this.state.order),
        this.state.categoryId
      )
      .then(() => {
        this.setState({rows: [...defaultRows, ...this.getDynamicRows()]});
      })
      .catch((err) => {
        this.setState({importInProgress: true});
        this.intervalID = setInterval(() => this.controlFetchDiscountCategories(), 2000);
      })
      .finally(() => this.props.updateLoader({globalLoading: false}));
  };

  fetchDiscountGroups = () => {
    return this.props.getDiscountGroups(0, 0);
  };

  handleRequestSort = (event, property) => {
    this.setState(prepareSortingState(this.state.orderBy, this.state.order, property), () =>
      this.fetchDiscountCategories()
    );
  };

  handleChangePage = (event, page, value) => {
    this.setState({page, value}, () => this.fetchDiscountCategories());
  };

  handleChangeRowsPerPage = (event) => {
    this.props.updateGlobalSettings({itemsPerPage: event.target.value}).then(() => {
      this.setState({page: 0}, () => this.fetchDiscountCategories());
    });
  };

  handleEnableEdit = (event) => {
    this.fetchDiscountCategories().then(() => {
      this.setState({inputLocked: false});
    });
  };

  handleAddNewGroup = (event) => {
    this.props.addDiscountGroup().then(() => {
      this.fetchDiscountCategories();
    });
  };

  handleSaveChanges = (event) => {
    this.setState({inputLocked: true});
    this.props.saveDiscountCategoriesChanges(this.props.discountCategoriesFormData).then(() => {
      this.fetchDiscountCategories();
    });
  };

  filterDiscountCategories = (values) => {
    this.setState(
      {
        categoryId: values.categoryId,
      },
      () => this.fetchDiscountCategories()
    );
  };

  handleResetDiscountCategoriesFiltering = () => {
    this.setState(
      {
        categoryId: null,
      },
      () => this.fetchDiscountCategories()
    );
  };

  handleDeleteDiscountGroupsDialogOpen = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.fetchDiscountGroups();
    this.setState({
      openDeleteDiscountGroups: true,
    });
  };

  handleDeleteDiscountGroupsDialogClose = () => {
    this.setState(
      {
        openDeleteDiscountGroups: false,
      },
      () => this.fetchDiscountCategories()
    );
  };

  warningRemoveDiscountGroup = (e, name, id) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({
      alert: (
        <WarningAlert
          title={<Trans>Are you sure?</Trans>}
          confirmCallback={() => this.confirmDeleteDiscountGroup(name, id)}
          closeCallback={() => this.hideAlert()}
          confirmBtnText={<Trans>Yes, delete!</Trans>}
          showCancel={true}
        >
          <Trans>You will delete discount group: {name}!</Trans>
        </WarningAlert>
      ),
    });
  };

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  confirmDeleteDiscountGroup = (name, id) => {
    this.props.updateLoader({globalLoading: true});
    return this.props
      .deleteDiscountGroup(id)
      .then(() => {
        this.setState({
          alert: null,
        });
        this.fetchDiscountCategories();
        this.fetchDiscountGroups();
        this.toastId = fireSuccessToast(
          <Trans>Discount group: {name} was successfully deleted.</Trans>
        );
      })
      .catch((error) => {
        this.setState({
          alert: null,
        });
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireErrorToast(error.errors._error, {autoClose: false});
      });
  };

  handleSingleLineEdit = (id) => {
    this.setState({
      activeLineEdit: id,
    });
  };

  saveSingleLineEdit = () => {
    const id = this.state.activeLineEdit;
    const values = this.props.discountCategoriesFormData.items[id];
    const data = {items: {}};
    data.items[id] = values;
    this.props.saveDiscountCategoriesChanges(data).then(() => {
      this.fetchDiscountCategories();
      this.setState({
        activeLineEdit: null,
      });
    });
  };

  controlFetchDiscountCategories = () => {
    return this.props
      .getDiscountCategories(
        this.state.page * this.props.globalSettings.itemsPerPage,
        this.props.globalSettings.itemsPerPage,
        getComputedOrderBy(this.state.orderBy, this.state.order),
        this.state.categoryId
      )
      .then(() => {
        clearInterval(this.intervalID);
        this.setState({rows: [...defaultRows, ...this.getDynamicRows()], importInProgress: false});
      });
  };

  startCallingDiscountGroups = () => {
    this.controlFetchDiscountCategories();
    this.setState({openConfirmImport: false, importInProgress: true});
    this.intervalID = setInterval(() => this.controlFetchDiscountCategories(), 2000);
  };

  handleImportDiscountGroups = (file) => {
    this.props.updateLocalLoader({localLoading: true});
    this.props
      .importDiscountGroup(file)
      .then((res) => this.setState({openConfirmImport: true}))
      .catch((err) => {
        this.setState({
          openConfirmImport: false,
          openErrorImport: true,
          importErrors: err.response.data?.error_list,
        });
      })
      .finally(() => this.props.updateLocalLoader({localLoading: false}));
  };

  handleExportDiscountGroups = () => {
    this.props.updateLocalLoader({localLoading: true});
    this.props
      .exportDiscountGroup()
      .then((res) => {
        download(res.data, res.headers['x-filename'], 'text/csv');
      })
      .finally(() => this.props.updateLocalLoader({localLoading: false}));
  };

  handleConfirmImport = () => {
    this.props.confirmImportDiscountGroup(this.props.discountCategoriesImportId).then((res) => {
      this.startCallingDiscountGroups();
    });
  };

  handleCloseConfirmImportDialog = () => {
    this.setState({openConfirmImport: false});
    this.props.updateLocalLoader({localLoading: false});
  };

  handleCloseErrorImportDialog = () => {
    this.setState({openErrorImport: false});
  };

  render() {
    const {classes} = this.props;
    const {order, page, orderBy, importInProgress} = this.state;

    return (
      <GridContainer>
        {importInProgress ? (
          <GridItem xs={12}>
            <div className={classes.importProgressBar}>
              <div className={classes.importProgressText}>
                <Trans>Import of discount groups is in progress</Trans>
              </div>
              <LinearProgress />
            </div>
          </GridItem>
        ) : (
          <>
            <GridItem xs={12}>
              <Card chart className={classes.filterCard}>
                <CardBody>
                  <FilterDiscountCategoriesForm
                    form="filterDiscountCategoriesForm"
                    onSubmit={this.filterDiscountCategories}
                    handleReset={this.handleResetDiscountCategoriesFiltering}
                  />
                </CardBody>
              </Card>
            </GridItem>

            <GridItem xs={12}>
              <DiscountGroupsTableToolbar
                handleEdit={this.handleEnableEdit}
                handleAdd={this.handleAddNewGroup}
                handleSave={this.handleSaveChanges}
                handleImport={this.handleImportDiscountGroups}
                handleExport={this.handleExportDiscountGroups}
                updateLoader={this.props.updateLocalLoader}
                inputLocked={this.state.inputLocked}
                openDeleteDiscountGroups={(e) => this.handleDeleteDiscountGroupsDialogOpen(e)}
              />

              {/* DELETE DISCOUNT GROUPS DIALOG */}
              <Dialog
                open={this.state.openDeleteDiscountGroups}
                handleClose={this.handleDeleteDiscountGroupsDialogClose}
                title={<Trans>Discount groups</Trans>}
                maxWidth={false}
                content={
                  this.props.discountGroups.loading === false ? (
                    <>
                      {this.state.alert}
                      {this.props.discountGroups.data.slice(1).map((group) => (
                        <RemovableTag
                          key={group.id}
                          content={group.name}
                          title={<Trans>Delete discount group {group.name}</Trans>}
                          onClick={(e) => this.warningRemoveDiscountGroup(e, group.name, group.id)}
                          deletableObject="DiscountGroup"
                        />
                      ))}
                    </>
                  ) : (
                    <div className={classes.CircularProgress}>
                      <CircularProgress />
                    </div>
                  )
                }
              />

              {/* IMPORT CONFIRMATION DIALOG */}
              <Dialog
                open={this.state.openConfirmImport}
                handleClose={this.handleCloseConfirmImportDialog}
                title={<Trans>Import discount groups sample</Trans>}
                maxWidth={false}
                submitName={<Trans>Save</Trans>}
                handleSubmit={this.handleConfirmImport}
                dialogActions={true}
                content={
                  <ImportDiscountGroupsList
                    data={this.props.discountCategoriesImportData}
                    order={order}
                    rows={[
                      ...this.state.dialogRows,
                      ...this.getDynamicRows(this.props.discountCategoriesImportData[0]),
                    ]}
                  />
                }
              />

              {/* IMPORT ERROR REPORT */}
              <Dialog
                open={this.state.openErrorImport}
                handleClose={this.handleCloseErrorImportDialog}
                title={<Trans>Errors which occurred during the import</Trans>}
                maxWidth={false}
                content={
                  <div>
                    {this.state.importErrors?.map((error, index) => (
                      <div key={index}>{error}</div>
                    ))}
                  </div>
                }
              />

              <div className={classes.tableWrapper}>
                <DiscountGroupsListForm
                  form="discountGroupsListForm"
                  initialValues={this.props.initialValues}
                  discountCategories={this.props.discountCategories}
                  handleRequestSort={this.handleRequestSort}
                  order={order}
                  rows={this.state.rows}
                  orderBy={orderBy}
                  inputLocked={this.state.inputLocked}
                  handleSingleLineEdit={this.handleSingleLineEdit}
                  saveSingleLineEdit={this.saveSingleLineEdit}
                  activeLineEdit={this.state.activeLineEdit}
                />
              </div>

              <CustomTablePagination
                rowsPerPageOptions={this.props.globalSettings.rowsPerPageOptions}
                count={this.props.total}
                rowsPerPage={this.props.globalSettings.itemsPerPage}
                page={page}
                changePage={this.handleChangePage}
                changeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </GridItem>
          </>
        )}
      </GridContainer>
    );
  }
}

export default compose(
  withStyles(discountGroupsTabStyle),
  connect((store) => {
    return {
      localLoading: store.LocalLoader,
      DiscountCategoriesListFilter: store.AppFilterStates.discountCategoriesListFilter,
      globalSettings: store.GlobalSettings,
      discountCategoriesFormData: getFormValues('discountGroupsListForm')(store),
      initialValues: store.DiscountCategories.initialFormValues,
      discountCategories: store.DiscountCategories.data,
      discountCategoriesImportData: store.DiscountCategories.importData,
      discountCategoriesImportId: store.DiscountCategories.importId,
      total: store.DiscountCategories.total,
      discountGroups: store.DiscountGroups,
    };
  }, mapDispatchToProps)
)(DiscountGroupsTab);
