import {cardTitle, grayColor20} from '_assets/global/jss/styleHelpers';

export const createUserPageStyle = (theme) => ({
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400',
    },
  },
  actionButtons: {
    position: 'absolute',
    top: '30px',
    right: '20px',
    width: 'auto',
    [theme.breakpoints.down('sm')]: {
      top: '60px',
    },
  },
  backBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '-20px',
    marginRight: '10px',
  },
  btnIcon: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  background: {
    background: grayColor20,
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.3)',
    padding: '0px 20px',
  },
});
