import {
  blackColor,
  dangerColor,
  grayButton,
  hexToRgb,
  primaryColor,
} from '_assets/global/jss/styleHelpers';

const warningAlertStyle = (theme) => ({
  sweetAlertPosition: {
    display: 'block',
    marginTop: '-140px',
  },
  button: grayButton,
  primary: {
    backgroundColor: primaryColor,
    boxShadow:
      '0 2px 2px 0 rgba(' +
      hexToRgb(primaryColor) +
      ', 0.14), 0 3px 1px -2px rgba(' +
      hexToRgb(primaryColor) +
      ', 0.2), 0 1px 5px 0 rgba(' +
      hexToRgb(primaryColor) +
      ', 0.12)',
    '&:hover,&:focus': {
      backgroundColor: primaryColor,
      boxShadow:
        '0 14px 26px -12px rgba(' +
        hexToRgb(primaryColor) +
        ', 0.42), 0 4px 23px 0px rgba(' +
        hexToRgb(blackColor) +
        ', 0.12), 0 8px 10px -5px rgba(' +
        hexToRgb(primaryColor) +
        ', 0.2)',
    },
  },
  danger: {
    backgroundColor: dangerColor[0],
    boxShadow:
      '0 2px 2px 0 rgba(' +
      hexToRgb(dangerColor[0]) +
      ', 0.14), 0 3px 1px -2px rgba(' +
      hexToRgb(dangerColor[0]) +
      ', 0.2), 0 1px 5px 0 rgba(' +
      hexToRgb(dangerColor[0]) +
      ', 0.12)',
    '&:hover,&:focus': {
      backgroundColor: dangerColor[0],
      boxShadow:
        '0 14px 26px -12px rgba(' +
        hexToRgb(dangerColor[0]) +
        ', 0.42), 0 4px 23px 0px rgba(' +
        hexToRgb(blackColor) +
        ', 0.12), 0 8px 10px -5px rgba(' +
        hexToRgb(dangerColor[0]) +
        ', 0.2)',
    },
  },
});

export default warningAlertStyle;
